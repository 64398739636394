.blog-sidebar {
    display: flex;
    flex-direction: column;
    flex-basis: 25%;
    position: sticky;
    top: 1.4rem; /* Adjust this value as needed to control the distance from the top */
    height: fit-content; /* Ensure the sidebar takes only the necessary height */
}

#dark .blog-view-search-wrapper input {
    background-color: #022852;
    color-scheme: dark;
}

#dark .blog-view-search-wrapper .blog-search-icon {
    color: white
}



#dark .blog-layout .blog-sidebar .sidebar-wrapper .blogSidebar-container {
    background-color: #02275263;
}

#dark .blog-layout .blog-sidebar .sidebar-wrapper .blogSidebar-container .sidebar-container-content h3,
#dark .blog-layout .blog-sidebar .sidebar-wrapper .blogSidebar-container .sidebar-container-content ul li a,
#dark .blog-layout .blog-sidebar .sidebar-wrapper .blogSidebar-container .sidebar-container-content .more-blogs-btn,
#dark .blog-layout .blog-sidebar .sidebar-wrapper .blogSidebar-container .sidebar-container-content .expand-btn{
    color: #ececec
}

#dark .blog-layout .blog-sidebar .sidebar-wrapper .blogSidebar-container .sidebar-container-content ul li a:hover,
#dark .blog-layout .blog-sidebar .sidebar-wrapper .blogSidebar-container .sidebar-container-content .more-blogs-btn:hover,
#dark .blog-layout .blog-sidebar .sidebar-wrapper .blogSidebar-container .sidebar-container-content .expand-btn:hover {
    color: #e4a906
}

.sidebar-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    height: 95vh
}

@media screen and (max-width: 992px) {
    .blog-layout {
        flex-wrap: wrap;
        margin-left: 15%;
        margin-right: 15%;
    }
    
    .blog-sidebar {
        flex-direction: column;
        width: 100%;
        flex-basis: auto;
        position: static;
    }

    .sidebar-wrapper {
        max-height: none; /* Reset max height for smaller screens */
        overflow-y: visible; /* Reset overflow for smaller screens */
        height: unset;
    }
}

.blog-layout {
    margin-top: 2rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: row;
    padding: 0;
    justify-content: center;
    gap: 0.8rem;
    margin-left: 15%;
    margin-right: 15%;
}

.blog-layout::-webkit-scrollbar {
    display: initial !important;
}

#dark .blog-content p a{
    color:#e4a906e7;
}

.blog-content p a:hover{
    color:#e4a906 !important;
}

.blog-content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: keep-all;
    hyphens: auto;
}

.blog-content p,
.blog-content a,
.blog-content h1,
.blog-content h2,
.blog-content h3,
.blog-content h4,
.blog-content h5,
.blog-content h6,
.blog-meta {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: keep-all;
    hyphens: auto;
}

.blog-content h1,
.blog-content h2,
.blog-content h3,
.blog-content h4,
.blog-content h5,
.blog-content h6 {
    display: block;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: keep-all;
    hyphens: auto;
    max-width: 100%; /* Ensure they don't exceed the container's width */
    white-space: normal; /* Prevents the text from being in a single line */
    text-align: left;
}

/* Target strong tags inside headings */
.blog-content h1 strong,
.blog-content h2 strong,
.blog-content h3 strong,
.blog-content h4 strong,
.blog-content h5 strong,
.blog-content h6 strong {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: keep-all;
    hyphens: auto;
    
}

.blog-content figure {
    margin: 1rem 0;
    text-align: center;
}

.blog-content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

#dark .blog-view {
    background-color: #02275263;
}

#dark .blog-view h1 {
    color: white
}

.blog-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 1rem;
    padding: 3rem;
    flex-basis: 85%;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
    transition: 0.6s ease;
    will-change: transform, box-shadow;
    width: 50%;
  }
  
  .blog-view:hover {
    box-shadow: 0 2.5px 7px rgba(0, 0, 0, 0.2);
    transition: 0.6s ease;
    will-change: transform, box-shadow;
  }
  
  .skeleton-wrapper {
    width: 100%; /* Ensure Skeletons take up full width */
    margin-bottom: 1rem; /* Add margin between Skeletons */
  }

  .blogcontent-skeleton-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the skeletons horizontally */
    justify-content: center; /* Centers the skeletons vertically */
    width: 100% !important;
    height: auto;
    position: relative;
}

.blogcontent-skeleton-wrapper > span {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers each skeleton element */
    justify-content: center; /* Ensures the content is centered */
    margin-bottom: 1rem; /* Ensures spacing between skeletons */
}

.blogcontent-skeleton-wrapper .react-loading-skeleton {
    margin-bottom: 1rem !important;
    display: block;
    width: 100%; /* Ensure the skeletons take up the full width */
    height: auto;
    background-color: var(--base-color, #e0e0e0) !important;

}

/* Specific skeleton widths */
.blogcontent-skeleton-wrapper > span:nth-child(1) .react-loading-skeleton {
    width: 70% !important;
    height: 70px !important;
}

.blogcontent-skeleton-wrapper > span:nth-child(2) .react-loading-skeleton {
    width: 50% !important;
    height: 25px;
}

.blogcontent-skeleton-wrapper > span:nth-child(3) .react-loading-skeleton {
    width: 40% !important;
    height: 30px;
}

.blogcontent-skeleton-wrapper > span:nth-child(4) .react-loading-skeleton {
    width: 60% !important;
    height: 35px;
}

.blogcontent-skeleton-wrapper > span:nth-child(5) .react-loading-skeleton {
    width: 100% !important;
    height: 15px;
}

.blogcontent-skeleton-wrapper > span:nth-child(6) .react-loading-skeleton {
    width: 100% !important;
    height: 300px;
}

  
  .react-loading-skeleton {
    background-color: #e0e0e0 !important; /* Standard background color for skeleton */
    border-radius: 4px; /* Slight border-radius for better aesthetics */
  }
  
.blog-image {
    width: 100%;
    border-radius: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.title {
    color: #022852;
    font-size: 3.7rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: normal;
    hyphens: auto;
}

#dark .blog-meta {
    color: #adadad;
}

.blog-meta {
    color: #696969;
    font-style: italic;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: keep-all;
    hyphens: auto;
    justify-content: center;
    text-align: center;
}

#dark .author-date {
    color: #adadad;
}

.author-date {
    color: #696969;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
  }


.date {
    margin-left: 0.5rem;
}

.blog-view div {
    line-height: 1.6;
    text-align: justify;
    word-wrap: break-word;
}

.blog-post-categories {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
}

#dark .blog-category {
    background-color: #afafaf;
    color: black;
}

.blog-category {
    display: flex; 
    background-color: #efefef;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 5px;
    font-size: 0.8rem;
    text-decoration: none;
    color: #022852;
    align-items: center;
    gap: 0.2rem;
}

.blog-category:hover {
    background-color: #cecece !important;
}

.blog-post-tags {
    display: flex; 
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;
}

.blog-tag {
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: #efefef;
    color: #333;
    padding: 5px 10px;
    border-radius: 1rem;
    font-size: 0.9rem;
    gap: 0.2rem;
    i {
        align-self: center;
    }
}

.blog-tag:hover {
    background-color: #cecece;
}

@media (max-width: 800px) {
    .blog-layout {
        margin-left: 5%;
        margin-right: 5%;
    }

    .blog-view {
        padding: 8%;
    }
    
    .title {
        font-size: 2.7rem;
    }
    .blog-post-categories {
        flex-direction: column;
        gap: 0.5rem
    }
    .blog-post-tags {
        flex-direction: column;
    }
    
}


@media (max-width: 400px) {
    .title {
        font-size: 2.1rem;
    }

}

@media (max-width: 275px) {
    .title {
        font-size: 1.4rem;
    }

}


.blog-view-search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.blog-search-icon {
    color: #333;
    left: 0;
    margin-left: 0.7rem;
    position: absolute;
    z-index: 3;
    font-size: 1.2rem;
    transition: 0.4s ease;
}

.blog-sidebar input[type="search"] {
    border: 1px solid transparent;
    border-radius: 1rem;
    background-color: white;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.15); /* Soft shadow for depth */
    transition: 0.6s ease;
    will-change: transform, box-shadow, color;
    transition: 0.6s ease;
    padding: 1rem;
    padding-left: 2.2rem;
    z-index: 3;
    width: 100%;
    outline: 1px solid transparent;
}

.blog-sidebar input[type="search"]:focus {
    outline: 1px solid rgb(90, 90, 90);
    transition: 0.4s ease
}

.blog-sidebar input[type="search"]:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    transition: 0.6s ease;
    transform: none;
    will-change: transform, box-shadow, color;    
}

#dark .search-results {
    background-color: #022852;
    a{
        color: white

    }
}

.search-results {
    position: absolute;
    top: 1rem; /* Adjust based on your input height */
    width: 99.5%;
    max-height: 200px; /* Set the max height for the scrollable area */
    overflow-y: auto;
    background: white;
    border: 1px solid #ddd;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .search-results ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 2rem;
  }
  
  .search-results li {
    padding: 0.8rem;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.2s ease;
  }
  
  #dark .search-results li:hover {
    background-color: rgba(248, 248, 248, 0.123);
  }

  .search-results li:hover {
    background-color: rgb(248, 248, 248);
    transition: background-color 0.2s ease;
  }

  .search-results a:last-child {
    border-bottom: none;
  }
  
  .search-results a {
    text-decoration: none;
    color: #333;
    transition: color 0.2s ease, text-decoration 0.2s ease;
  }
  
  .search-results a:hover {
    text-decoration: underline;
    color: #e4a906;
    transition: color 0.2s ease, text-decoration 0.2s ease;
  }
