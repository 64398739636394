.roles-admin-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-family: Arial, sans-serif;
    margin-left: 15%;
    margin-right: 15%;
}

h2 {
    margin-bottom: 1rem;
}

.roles-table-wrapper,
.role-levels-table-wrapper {
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 2rem;
}

.role-levels-table-wrapper {
    width: 40%; /* Adjust this value to set the desired width */
    margin: 0 auto; /* This will center the table */
    margin-bottom: 2rem;
}


.roles-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.roles-table th, .roles-table td {
    border: 1px solid #dddddd6e;
    padding: 8px;
}

.roles-table tbody tr {
    background-color: white;
    transition: 0.2s ease;
    color: black
}

.roles-table tbody tr:hover {
    background-color: rgb(236, 236, 236);
    transition: 0.2s ease;
}

.roles-table th {
    background-color: #022852;
    color: white;
}

.roles-table .role-tag {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    margin: 0.2rem;
    border-radius: 5px;
    background-color: #efefef;
    color: #333;
    font-size: 0.9rem;
    position: relative;
    align-items: center;
    text-align: center;
}

.roles-table .role-tag button {
    margin-left: 0.5rem;
    background: none;
    border: none;
    background-color: rgba(95, 95, 95, 0.192);
    border-radius: 50%;
    cursor: pointer;
    transition: color 0.2s ease, background-color 0.2s ease;
}

.roles-table .role-tag button:hover {
    background-color: #9e080877;
    transition: color 0.2s ease, background-color 0.2s ease;
}

.roles-table .role-tag.siteadmin-tag {
    background-color: #022852;
    color: white;
}

.role-levels-table-wrapper .roles-table tbody {
    line-height: 2.82em;
}

.role-levels-table-wrapper .roles-table th, .role-levels-table-wrapper .roles-table td {
    border: 0;
}



.role-actions-column {
    width: 40px
}

.role-levels-actions {
    text-align: right;
    text-indent: 0;
    padding-left: 0 !important;
    padding-right: 10px;
}

.role-add-form {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.role-add-form select,
.role-add-form input,
.new-role-form input,
.role-add-form button,
.new-role-form button {
    margin: 0.5rem;
    padding: 10px 20px;
    font-size: 1rem;
}

.role-add-form button,
.new-role-form button {
    background-color: white;
    color: black; /* Change this color to fit your design */
    border: none;
    cursor: pointer;
    transition: background-color 0.22s ease;
}

.role-add-form button:hover,
.new-role-form button:hover {
    background-color: #bebebe88;
}
