.html-sitemap-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the entire sitemap content */
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: inherit;
    margin-bottom: 6.9rem;
}

.sitemap-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal-width columns */
    grid-template-rows: auto auto; /* Two rows that adjust based on content */
    gap: 2rem; /* Space between grid items */
    max-width: 800px; /* Maximum width for the sitemap container */
    width: 100%; /* Ensure the container takes up available width */
    justify-items: stretch; /* Stretch items to fit their grid cells */
}

.sitemap-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
}

.sitemap-section {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1.5rem; /* Adjust padding to control section size */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%; /* Ensure each section takes up the full width of its grid cell */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.sitemap-heading {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}

.sitemap-list {
    list-style: none;
    padding-left: 0;
}

.sitemap-item {
    margin-bottom: 0.8rem;
}

.sitemap-link {
    text-decoration: none;
    font-size: 1.1rem;
    color: #004896;
    transition: color 0.2s ease;
}

.sitemap-link:hover {
    color: #e4a906;
}
