.services-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100vh; /* Ensure the page takes full height */
}

.section-background.blue.top.services-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  gap: 8rem;
  padding-bottom: 15rem;
}

.section-background.blue.top.services-page::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; /* Start from the bottom */
  height: 15rem; /* Same as the top value in homePageFooter */
  background: linear-gradient(to bottom, transparent, rgb(1, 0, 29));
  z-index: 2; /* Ensure it's above the content but below the overlay if any */
}

#dark .section-background.blue.top.services-page::before {
  background-color: rgba(0, 2, 19, 0.82);
}

/* Overlay */
.section-background.blue.top.services-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 2, 19, 0.75);
  z-index: 1;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.service-entry {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 15%;
  margin-right: 15%;
  width: 70%;
  opacity: 0;
  transform: translateY(8rem);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  will-change: opacity, transform;
}

.service-info {
  flex: 1;
  padding-right: 2rem;
  text-align: left;
}

.service-image img {
  width: auto;
  height: auto;
  max-height: 29rem;
  max-width: 33rem;
  border-radius: 25%;
  object-fit: cover;
  transition: transform 1s ease, box-shadow 1s ease;
  will-change: transform;
  /*filter: drop-shadow(1mm 1mm 10mm #e4a90681);*/
}

/* Animation state */
.service-entry.slide-up .service-image img {
  transform: scale(1.13);
}

/* Animation when the service entry is in view */
.slide-up {
  opacity: 1;
  transform: translateY(0);
}

.service-title {
  font-size: 1.8rem;
  color: white;
  margin-bottom: 1rem;
  text-shadow: 0px 0px 6px #989898;
}

.service-separator {
  border: 0;
  height: 2px;
  background-color: #e4a906;
  margin-bottom: 1rem;
  width: 100%;
}

.service-description {
  font-size: 1.1rem;
  color: white;
  line-height: 1.6;
  text-shadow: 0px 0px 4px #989898;
}

@media (max-width: 1200px) {
  .service-image {
    padding-right: 5rem;
  }
}

@media (max-width: 1200px) {
  .service-entry {
    flex-direction: column;
    align-items: center; /* Updated to center for better alignment */
    gap: 2rem;
  }

  .service-title {
    font-size: 1.4rem;
  }

  .service-info,
  .service-image {
    max-width: 100%;
    padding: 0; /* Ensure there's no padding right for a full-width alignment */
  }

  .service-image {
    order: -1; /* Image goes above text */
    margin-top: 0; /* Adjusted to remove extra space since the image will be on top */
    margin-bottom: 1rem; /* Space between image and text */
  }

  .service-image img {
    max-width: 80%; /* Optional: Adjust if you want to limit the image width */
    max-height: auto; /* Adjust height as needed, or use 'auto' for natural aspect ratio */
  }
}
