.services-section {
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
    color: white; /* Adjust as needed */
    padding-bottom: 7rem;
  }
  
  .container {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .service-box {
    flex-basis: calc(25% - 20px); /* Adjust as needed */
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional */
    padding: 20px;
    background: rgba(255, 255, 255, 0.1); /* Adjust as needed */
    text-align: center;
  }
  
  /* Add media queries for responsiveness */
  @media (max-width: 768px) {
    .service-box {
      flex-basis: calc(50% - 20px);
    }
  }
  
  
  .services-heading {
    opacity: 0;
    transform: translateY(3rem);
    transition: all 0.8s ease-in-out;
  }
  
  .services-heading.slide-up {
    opacity: 1;
    transform: translateY(0);
  }
  
  .service-card {
    opacity: 0;
    transform: translateY(8rem);

  }
  
  .service-card.slide-up {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.8s ease-in-out;
  }

.plus-more .service-card {
  color: #e4a906;
  outline: 1px solid #e4a906;
  background-color: transparent;
  text-shadow: none;
  /*box-shadow: 0 9px 30px transparent;*/
}

.plus-more .service-card:hover {
  color: #e4a906;
  background-color: transparent;
  /*box-shadow: 0 9px 30px rgba(0, 0, 0, 0.55);*/
  box-shadow: none;
  transform: none;
  outline: 1px solid #e4a906;
}

.plus-more .service-card .icon-circle-container .icon-circle {
  fill: #022852;
  transition: fill 0.5s ease;
}

.plus-more .service-card .icon-circle-container .icon-graphics {
  stroke: #e4a906;
  fill: #022852;
  transition: 0.5s ease;
}