.navbar {
  display: flex;
  align-items: center;
  background-color: inherit;
  gap: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  width: 100%;
  background: inherit;
}





#dark .navbar .navBarWrapper .navlink-container .navLinkWrapper .navLinks a {
  color: white !important;
}

#dark .navbar .navBarWrapper .navlink-container .navLinkWrapper .navLinks a.active {
  color: #e4a906 !important;
}

.navbar-placeholder {
  height: 9.15rem; /* Same height as your navbar */
  width: 100%;
}

.navbar-fixed {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  background-color: transparent !important;
  z-index: 10000 !important;
  height: 4rem !important;
  visibility: visible !important;
  opacity: 1 !important;
  width: 100%;
}

.navbar-fixed .navBarWrapper {
  border-radius: 2rem;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.558) !important;
  margin-left: 0;
  margin-right: 0;
  margin-top: 3.5rem;
  padding-left: 10%;
  padding-right: 10%;
  height: 7.5rem;
  transition: background-color 0.3s ease-in-out, filter 0.3s ease, position 0.3s ease;
}

.navbar-fixed .navBarWrapper .smallScreenWrapper .logo-svg {
  transform: scale(1.28) translateY(0.3rem) !important;
}

.navbar-fixed .navBarWrapper .smallScreenWrapper .logo-svg .www {
  filter: drop-shadow(0 0 20px #e4a9068f);
}

/* Glow effect for the lumens part */
.navbar-fixed .navBarWrapper .smallScreenWrapper .logo-svg .lumens {
  filter: drop-shadow(0 0 40px #e4a906);
  
}

.navbar-fixed .navBarWrapper .smallScreenWrapper .logo-svg .bulb {
  fill: #022852;
  filter: drop-shadow(0 -14rem 140px #e4a906);
}


.navbar-fixed .navBarWrapper .smallScreenWrapper .logo-svg:hover .bulb {
  fill: #e4a906d2;
}

  .navbar-fixed .navbar-yellow-line {
    display: none;
  }

  .animate-slide-down {
    animation: slideDown 0.55s ease forwards;
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-200%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .navBarWrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-left: 15%;
    margin-right: 15%;
    background-color: transparent !important;
  }
  .navlink-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    z-index: 10!important;
  }
  .navLinks {
    display: inline-flex;
    gap: 1rem;
  }
  .navLinkWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    width: 100%;
    transform: translateX(0%) !important;
    will-change: transform;
  }

  .navbar-yellow-line {
    height: 3px;
    background-color: #727272; /* Initial color */
    width: 0; /* Start with a width of 0 */
    position: absolute;
    margin-top: 6.25rem;
    margin-left: -0.2rem;
    z-index: 0;
    animation: expandYellowLine 0.45s ease forwards, changeColor 0.15s ease-in forwards;
    animation-delay: 0.4s, 1.1s; /* First animation delay, second animation delay */
  }

  .theme-toggle-wrapper:hover {
    translate: 0 -0.1rem;
    
  }

  #light .theme-toggle-wrapper #light-switch:focus + #light-switch-label .switch {
    outline: 10px solid black; /* Blue outline when focused */
    outline-offset: 15px; /* Offset the outline to create a gap */
  }
  
  #dark .theme-toggle-wrapper #light-switch:focus + #light-switch-label .switch {
    outline: 10px solid white; /* Blue outline when focused */
    outline-offset: 15px; /* Offset the outline to create a gap */
  }

  .theme-toggle-wrapper {
    position: relative; /* Position the switch absolutely */
    scale: 0.3;
    transform: rotate(90deg); /* Properly scale down the switch */
    transform-origin: top right; /* Make sure scaling happens from the top-left corner */
    z-index: 10; /* Ensure the lightswitch stays on top of other elements */
    transition: translate 0.2s ease-in-out;
  }

  .theme-toggle-wrapper #light-switch-label .switch {
    box-shadow: 0px 0px 8px black;
    &:before {
      transform: rotate(-90deg); /* Reverse the rotation of the icon */
      background-color: transparent !important;
      top: 0 !important
    }

    &:after {
      transform: rotate(-90deg); /* Reverse the rotation of the icon */
      background-color: transparent !important;
      box-shadow: none !important;
      bottom: 3px !important
    }
  }


  .navbar-fixed .theme-toggle-wrapper {
    top: 1.3%; /* Adjust top position relative to the navbar */
  }
  
  /* Scoped transitions for lightswitch */
  #light-switch-label, 
  #light-switch-label .screw, 
  #light-switch-label .switch, 
  #light-switch-label .switch:before, 
  #light-switch-label .switch:after {
    transition: .2s ease-in-out;
  }
  
  #light-switch {
    position:absolute;
    left:0;
    top:0;
    opacity:0;
    
    &:checked ~ #light-switch-label {
      box-shadow:0 2px 2px #d0b57b;
      .switch {
        box-shadow: 0 10px 10px -5px rgb(99, 99, 99), 0 0 0 1px rgba(99,99,99,.1), 0 0 0 4px rgb(158, 158, 158), 0 0 0 5px rgba(99,99,99,.1);
        &:before {
          height: 75px;
          top: 0px;
          line-height: 75px;
          background: transparent;
          color: #e4a906ec;
          text-shadow: 0 0 3px #e4a906;
          font-size: 4rem;
        }
        &:after {
          height: 70px;
          bottom: 8px;
          line-height: 70px;
          box-shadow: 0 6px 0 #acacacb6;
          background: #acacacb6;
          color: #dbdbdbbe;
          text-shadow: 0 0 0px transparent;
          font-size: 3.4rem;
        }
      }
    }
  }
  
  #light-switch-label {
    position:relative;
    display:block;
    z-index:1;
    margin:0;
    background-color:transparent;
    border-radius:10px;
  
    .switch {
      cursor: pointer;
      height: 150px;
      width: 100px;
      background: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -75px 0 0 -50px;
      border-radius: 5px;
      overflow: hidden;
      transform: scaleY(1.2);
      box-shadow: 0 10px 10px -5px rgb(99, 99, 99), 0 0 0 1px rgba(99,99,99,.1), 0 0 0 4px rgb(158, 158, 158), 0 0 0 5px rgba(99,99,99,.1);
  
      /* Sun icon for ON state */
      &:before {
        content: '\f186'; /* Font Awesome code for moon icon */
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        display: block;
        position: absolute;
        height: 70px;
        font-size: 3.4rem;
        text-align: center;
        line-height: 70px;
        width: 100px;
        top: 2px;
        left: 0;
        background: #acacacb6;
        color: #dbdbdbbe;
        border-radius: 5px 5px 0 0;
      }
  
      /* Moon icon for OFF state */
      &:after {
        content: '\f185'; /* Font Awesome code for sun icon */
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        display: block;
        position: absolute;
        height: 75px;
        font-size: 4rem;
        text-align: center;
        line-height: 75px;
        width: 100px;
        bottom: 8px;
        left: 0;
        background: transparent;
        color: #e4a906ec;
        text-shadow: 0 0 3px #ffffffef;
        border-radius: 0 0 5px 5px;
      }
    }
  }
  
  #switch-background {
    position:absolute;
    height:100%;
    width:100%;
    top:0;
    left:0;
    background-color:#132436;
  }
  





  
  #dark .fa-plug {
    color: #77b9ff2a;
  }

  
  .fa-plug {
    position: absolute;
    right: 0;
    opacity: 0;
    transform: rotate(180deg);
    color: #022752de;
    margin-right: -0.3rem;
    animation: slideAndFadeInPlug 0.35s ease forwards;
    animation-delay: 0.9s; /* Adjust based on when you want the animation to start */
  }
  
  @keyframes slideAndFadeInPlug {
    from {
      opacity: 1;
      transform: rotate(180deg) translateY(20%) scaleY(0.5); /* Start above the final position */
      margin-right: -0.3rem;
    }
    to {
      opacity: 1;
      font-size: 2.6rem;
      transform: rotate(180deg) translateY(-10%) scaleY(1.1); /* End at the final position */
      margin-right: -0.88rem;
    }
  }
  
  @keyframes expandYellowLine {
    from {
      width: 0;
    }
    to {
      width: 33.3%; /* Adjust to your preferred final width */
    }
  }
  
  @keyframes changeColor {
    from {
      background-color: #727272b2;
    }
    to {
      background-color: #e4a9067a; /* Final color */
    }
  }
  
  #dark .navLinkBlog a {
    outline-color: #e4a906;
  }

  #dark .navLinkBlog a:focus {
    outline-color: white;
  }

  .navLinkBlog a{ 
    display: flex;
    gap: 0.5rem;
    height: 100%;
    text-align: center;
    align-items: center;
    background-color: #e4a906;
    padding: 0.8rem !important;
    border-radius: 0.5rem;
    outline: 2px solid #022852;
    text-shadow: none !important;
    transition: 0.2s ease !important;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25),
    -2px 2px 3px rgba(0, 0, 0, 0.4);

    i{
      font-size: 1.4rem;
    }
  }

  .navLinkBlog a:hover, .navLinkBlog a:focus, .navLinkBlog a.active {
    display: flex;
    border-radius: 1.5rem;
    text-shadow: none !important;
    box-shadow: none !important;
    opacity: 1 !important;
    transition: 0.2s ease !important;
    outline-offset: 0.3rem;
  }

  .network, .lightbulb {
      transition: fill 0.2s ease, stroke 0.2s ease;
  }

  #dark .skip-to-content-link {
    color: #e4a906
  }

  .skip-to-content-link {
    position: absolute;
    transform: translateY(-300%);
    transition: transform 0.3s;
    margin-top: -10rem;
    margin-left: -13.3rem;
    margin-right: 0.5rem;
    justify-content: center;
    align-self: center;
    border-radius: 5rem;
  }

  .skip-to-content-link:focus {
    transform: translateY(0%);
    margin-top: 0rem;
  }
.logo-svg {
    display: block;
    padding: 0;
    margin-right: 1rem;
    z-index: 3;
}

.logo-svg a {
    padding: 0 !important;
    height: 90px !important;
    z-index: 3;
}

/* Animation for www elements to fade in color on load */
@keyframes www {
    from { fill: rgba(0, 0, 0, 0); }
    to { fill: #e4a906; }
}

/* Define fill transition for www elements, start animation on initial load */
.logo-svg .www {
    transition: fill 0.4s ease; /* Smooth transition for fill color */
    animation: www 0.9s ease forwards 1.1s; /* Animation to change fill color */
    will-change: fill;
}

/* Initial setup for lumens element without initial fill animation */
.logo-svg .lumens {
    transition: fill 0.4s ease, transform 1s ease; /* Only transitions, no initial fill */
    will-change: fill, transform;
}

/* Apply fill color directly in React component instead of using CSS animation for fill */
.logo-svg:hover .lumens {
    transform: scale(3.9) translate(-27px, -24px); /* Hover state transformation */
    transition: fill 0.4s ease, transform 1s ease; /* Transitions for hover */
}

/* Animation for lumens to appear with color and transformation on initial load */
@keyframes lumensAppear {
    from {
        fill: rgba(0, 0, 0, 0); /* Start from transparent */
        transform: scale(0.59) translate(-15.5rem, -5.2rem); /* Initial scale and position */
    }
    to {
        fill: #e4a906; /* Transition to yellow */
        transform: scale(0.77) translate(-21rem, -9.8rem); /* End scale and position */
    }
}


    .skip-link {
      position: absolute;
      top: -40px;
      left: 0;
      background: #000;
      color: white;
      padding: 8px;
      z-index: 100;
      transition: top 0.3s, visibility 0.3s;
      visibility: hidden;
    }
    
    .skip-link:focus {
      top: 0;
      visibility: visible; /* Make it visible on focus */
    }
    
    
/* Define keyframes for the yellow line animation */
@keyframes shootUpLines {
  0% {
    height: 0;
    background: linear-gradient(
      to right,
      transparent 0%,
      #e4a9066b 3%,
      transparent 6%,
      #e4a9066b 9%,
      transparent 12%,
      #e4a9066b 15%,
      transparent 18%,
      #e4a9066b 21%,
      transparent 24%,
      #e4a9066b 27%,
      transparent 30%,
      #e4a9066b 33%,
      transparent 36%,
      #e4a9066b 39%,
      transparent 42%,
      #e4a9066b 45%,
      transparent 48%,
      #e4a9066b 51%,
      transparent 54%,
      #e4a9066b 57%,
      transparent 60%,
      #e4a9066b 63%,
      transparent 66%,
      #e4a9066b 69%,
      transparent 72%,
      #e4a9066b 75%,
      transparent 78%,
      #e4a9066b 81%,
      transparent 84%,
      #e4a9066b 87%,
      transparent 90%,
      #e4a9066b 93%,
      transparent 96%,
      #e4a9066b 99%,
      transparent 100%
    );
    width: 100%;
    border-bottom-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
  }
  100% {
    height: 200%; /* Extend beyond the height of the nav link */
    background: linear-gradient(
      to right,
      transparent 0%,
      #e4a9066b 3%,
      transparent 6%,
      #e4a9066b 9%,
      transparent 12%,
      #e4a9066b 15%,
      transparent 18%,
      #e4a9066b 21%,
      transparent 24%,
      #e4a9066b 27%,
      transparent 30%,
      #e4a9066b 33%,
      transparent 36%,
      #e4a9066b 39%,
      transparent 42%,
      #e4a9066b 45%,
      transparent 48%,
      #e4a9066b 51%,
      transparent 54%,
      #e4a9066b 57%,
      transparent 60%,
      #e4a9066b 63%,
      transparent 66%,
      #e4a9066b 69%,
      transparent 72%,
      #e4a9066b 75%,
      transparent 78%,
      #e4a9066b 81%,
      transparent 84%,
      #e4a9066b 87%,
      transparent 90%,
      #e4a9066b 93%,
      transparent 96%,
      #e4a9066b 99%,
      transparent 100%
    );
    transform: translateY(-200%); /* Move up to disappear at the top */
    width: 100%;
    left: 0;
    right: 0;
    border-bottom-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
  }
}

.navbar a {
  position: relative;
  color: #022852;
  text-align: center;
  padding: 0rem 0.3rem;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 400;
  overflow: hidden;
  transition: 0.35s ease-out;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  will-change: transform, color, text-shadow, opacity;
}

.navbar .navLinks a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  background-color: #e4a906c9;
  height: 0.17rem;
  border-radius: 35%;
  transition: left 0.25s ease, right 0.25s ease;
  box-shadow: 0 -0.06rem 6px #e4a906;
}

/* Activate yellow bar on the active class */
.navbar a.active::before {
  left: 0;
  right: 0;
  opacity: 1;
}

.navbar .navLinks a {
  box-sizing: border-box;
  z-index: 5;
  padding: 0.69rem 0.33rem;
  margin-left: 0.4rem;
  font-weight: 500;
}

.navbar .navLinks:hover a {
  opacity: 0.5;
  transition: 0.25s ease;
}

.navbar:hover .logo-svg * {
  opacity: 1 !important;
}

.navbar .navLinks a:hover {
  opacity: 1 !important;
  transform: translateY(-0.2rem);
}

.navbar a.active::before {
  opacity: 1;
}

.navbar .navLinks:hover a.active::before {
  opacity: 1;
  transition: 0.25s ease;
}

#dark .navbar .navLinks .active {
  text-shadow: 0px 0px 5px #e4a906a1, 0px 0px 10px #e4a906cb, 0px 0px 15px #e4a906f6 !important;
}


.navbar .navLinks .active {
  transition: 0.15s ease-out;
  color: #2770c4ea;
  transform: translateY(-0.2rem) !important;
}

.hamburger-toggle {
  display: none; /* Hide by default */
}

/* Adjust visibility based on screen size */
@media screen and (max-width: 911px) {

  #dark .navbar .navBarWrapper .navlink-container {
    background-color: rgb(1,0,29) !important;
  }

  .navBarWrapper {
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
  }

  .smallScreenWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .logo-svg {
    z-index: 10;
    order: 1;
  }

  .opened-hamburger-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .opened-hamburger-container .hamburger-toggle {
    margin-left: 1rem;
  }


  .hamburger-container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    order: 2;
  }

  .hamburger-toggle {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    width: 35px;
    height: 28px;
    transition: transform 0.35s ease-out;
    transform: scale(1.3);
  }

  #dark .hamburger-toggle .line {
    background-color: #dadada;
  }

  .hamburger-toggle .line {
    width: 100%;
    height: 4px;
    background-color: #333;
    transition: all 0.35s ease;
  }

  .hamburger-toggle .line.middle {
    opacity: 1;
  }

  .hamburger-toggle .line.top.open {
    transform: rotate(225deg) translate(-5px, -12px);
  }

  .hamburger-toggle .line.middle.open {
    opacity: 0;
  }

  .hamburger-toggle .line.bottom.open {
    transform: rotate(-225deg) translate(-5px, 12px);
  }

  .opened-hamburger-container .hamburger-toggle .line.top.open {
    transform: rotate(225deg) translate(-8.5px, -22px) scaleX(0.7);
  }

  .opened-hamburger-container .hamburger-toggle .line.middle.open {
    opacity: 1;
    transform: translate(-0px, 2px);
  }

  .opened-hamburger-container .hamburger-toggle .line.bottom.open {
    transform: rotate(-225deg) translate(-6px, 19px) scaleX(0.7);
  }

  #light .hamburger-toggle:hover .line {
    background-color: rgb(129, 129, 129) !important;
  }

  #light .hamburger-toggle:focus .line {
    background-color: #333;
  }

  #dark .hamburger-toggle:hover .line {
    background-color: rgb(255, 255, 255) !important;
  }


  #dark .navbar-fixed .navBarWrapper .smallScreenWrapper .hamburger-container .hamburger-toggle .line {
    background-color: rgba(255, 255, 255, 0.774);
    outline: 1px solid rgba(14, 14, 14, 0.658);
  }

  .navbar-fixed .navBarWrapper .smallScreenWrapper .hamburger-container .hamburger-toggle .line {
    background-color: rgba(14, 14, 14, 0.658);
    outline: 1px solid rgba(255, 255, 255, 0.774)
  }

  .AppWrapper.menu-open header nav .navBarWrapper .navlink-container {
    right: 0 !important;
    background-color: transparent !important;
  }

  .AppWrapper.menu-open header .navbar-fixed .navBarWrapper {
    background-color: transparent !important;
    transition-delay: 0 !important;
  }

  .AppWrapper header .navbar-fixed .navBarWrapper {
    background-color: rgba(83, 83, 87, 0.568) !important;
    transition-delay: 0 !important;
  }
  

  .navlink-container {
    position: fixed;
    right: 0%;
    top: 0; /* Adjust this to the height of your fixed navbar */
    height: 100%; /* Full height */
    width: 60%; /* Ensure this matches the remaining width when translated */
    transform: translateX(100%); /* Hidden by default */
    z-index: 9; /* Above other elements */
    background-color: inherit; /* Match your design */
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease, right 0.3s ease;
  }

  .navLinkWrapper {
    position: sticky;
    top: 3%;
    right: 0;
    width: 100%;
    max-height: 95.5dvh;
    height: 100%;
    background-color: inherit;
    display: flex;
    flex-direction: column;
    z-index: 9;
    justify-content: space-between !important;
    transition: margin-top 0.6s ease, height 0.6s ease, max-height 0s ease; /* Add transition for smooth movement */
  }

  .navbar .navLinkWrapper {
    top: 10.5%;
    max-height: 88.1dvh;
    transition: margin-top 0.6s ease, height 0.6s ease, max-height 0.6s ease;
  }

  .navbar-fixed .navLinkWrapper {
    top: 2%;
    max-height: 97dvh;
    transition: max-height 0.6s ease;
  }

  .navbar .navLinkWrapper .logo-svg {
    transition: margin-top 0.6s ease
  }

  .navbar-fixed .navLinkWrapper .smallScreenWrapper .logo-svg {
    margin-top: 0;
  }

  .navLinkWrapper .navLinks {
    flex-direction: column;
    width: 100% !important;
    justify-content: center !important;

  }

  /* Ensure text is aligned left */
  .navLinkWrapper .navLinks a {
    text-align: left;
    background-color: transparent;
    color: inherit;
    text-decoration: none;
    width: 85%;
    margin-left: 10%;
    border-bottom: 1px solid rgb(158, 158, 158);
    display: flex;
    justify-content: space-between !important;
    overflow: visible;
  }

  /* Ensure text is aligned left */
  .navLinkWrapper .navLinks a span {
    display: flex;
    flex-direction: row;
    padding-bottom: 0.5rem;
  }

  /* Active indicator is hidden by default */
  .active-indicator {
    display: none;
    width: 4px;
    height: 100%;
    background-color: #e4a906;
    box-shadow: 0px 0px 8px #e4a906, 0px 0px 16px #e4a906;
    margin-right: 1rem;
  }

  /* Show active indicator and hide chevron for active links */
  .navLinkWrapper .navLinks .active .active-indicator {
    display: block !important;
  }

  .navLinkWrapper .navLinks .active i.fa-chevron-right {
    display: none !important;
  }


  /* Show when the link is active */
  .navLinkWrapper .navLinks .active .active-indicator {
    display: block !important;
  }


  .navLinkWrapper .navLinks a:last-child {
    border-bottom: none;
  }  

  /* Remove the yellow underline on small screens */
  .navLinkWrapper .navLinks a.active::before {
    content: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    transition: none !important;
  }

  .navLinkWrapper .logo-svg {
    margin-right: 0;
    order: unset;
  }

  .navLinkBlog {
    width: 9.5rem;
    justify-content: center;
    text-align: center;
  }

  .navLinkBlog a {
    justify-content: center;
    border-radius: 1.6rem;
    box-shadow: none;
  }

  .navLinkBlog a:hover {
    background-color: #022852;
    color: #e4a906;
  }

  .navbar-yellow-line {
    margin-left: 3.5rem;
  }

  .skip-to-content-link {
    display: none;
  }

  .opened-hamburger-container {
    display: none;
    justify-content: center;
    width: 100%;
    margin-top: 0; /* Initial margin-top set to 0 */
    transition: margin-top 0.3s ease; /* Add transition */
  }

  .navbar-fixed .opened-hamburger-container {
    display: flex;
  }

}


.navlink-container .navbar-consultation {
  width: 100%;
  display: flex;
  justify-content: center;
  transition: 0.1s ease;
  bottom: 0;

  a {
    bottom: 0;
    width: 50%;
    border-radius: 3rem;
    transition: 0.1s ease;
    padding: 0.4rem 2.5rem;
    background-color: #022852;
    outline: 3px solid #e4a906;
    
    color: #e4a906;
    font-size: 1rem;
    font-weight: bold;
    transition: 0.2s ease;
  }

  a:hover, a:focus {
    background-color: #e4a906;
    outline: 3px solid #022852;
    color: #022852;
    transition: 0.2s ease;
  }


  /* Other CSS styles for your nav links */
  .navLinkWrapper .navLinks a {
    text-align: left;
    color: inherit;
    background-color: transparent;
  }

  .navLinkWrapper .navLinks a:hover {
    opacity: 1 !important;
    transform: none !important; /* Remove any transform effects on hover */
  }

    /* Also ensure to reset the hover state if necessary */
    .navLinkWrapper .navLinks a:hover::before {
      content: none !important; /* Remove the hover effect */
      background-color: transparent !important; /* Ensure no background */
      box-shadow: none !important; /* Remove any box shadow */
      transition: none !important; /* Remove any transitions */
    }

    .navlink-container .navbar-consultation {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      transition: 0.1s ease;
    
    
      a {
        position: absolute;
        bottom: 0;
        width: 50%;
        border-radius: 2rem;
        padding: 0.4rem 1.1rem;
        color: #e4a906;
        font-size: 0.7rem;
        font-weight: bold;
        transition: 0.2s ease;
      }
    }

}


@media screen and (max-height: 700px) {
  .navbar .navLinkWrapper .logo-svg {
    margin-top: 12%;
    margin-bottom: 0rem;
  }

  .navLinkWrapper .navLinks a {
    font-size: 1.1rem;
    padding-top: 0;
    padding-top: 0;
  }

  .navLinkWrapper .navLinks a span{
    padding: 0rem;

  }

  
}


.navbar-consultation.at-top {
  transition: 0.1s ease;
}



@media screen and (max-width: 300px) {
  .navBarWrapper {
    margin: 0;
    padding: 0;
  }

  .smallScreenWrapper {
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    padding: 0;
  }

  .hamburger-toggle {
    order: 1;
  }

  .navbar-yellow-line {
    margin-top: 9.2rem;
  }

  .navlink-container .navbar-consultation {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    transition: 0.1s ease;
  
  
    a {
      position: absolute;
      bottom: 0;
      width: 50%;
      border-radius: 2rem;
      padding: 0.3rem 1.1rem;
      color: #e4a906;
      font-size: 0.7rem;
      font-weight: bold;
      transition: 0.2s ease;
    }
  }
}
