/* Base styles for the hero section */
.hero {
    height: min-content;
}

/* Styling for the hero carousel container */
.hero-carousel {
    width: 100%;
}

.hero-carousel .skeleton-wrapper {
    width: 100%;
    height: 77vh;
}

.hero-carousel .slick-slider,
.hero-carousel .slick-list,
.hero-carousel .slick-track {
    height: min-content !important;
}

/* Ensuring the slider elements take full height */
/* Positioning and sizing of each slide */
.hero-carousel .slick-slide {
    position: relative;
    min-height: 77vh; /* Ensure the slides have a minimum height */
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center; /* Center the background images */
}


.slide-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}

.hero-carousel .slick-slide img {
    width: 100%; /* Ensures image covers the full width of the slide */
    height: 100%; /* Ensures image covers the full height of the slide */
    object-fit: cover; /* Cover the area without distorting aspect ratio */
    object-position: center; /* Center the image within the slide */
}

/* Base styles for slide content */
.slide-content {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 1.5rem;
    padding-top: 0;
    text-align: left;
    z-index: 2; /* Ensure the content is above the background */
    border-radius: 10px;
    max-width: 45%;
}

.slide-content.left {
    left: 15%;
}

.slide-content.right {
    right: 15%;
    text-align: center;
}

.slide-content.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.slide-content h1 {
    font-size: 3rem;
    color: #fff;
    font-weight: bolder;
    text-shadow: 3px 3px 9px black;
}

.slide-content p {
    color: #fff;
    max-width: 35rem;
    margin-block-start: -1rem;
    margin-block-end: 3.3rem;
    font-size: 1.4rem;
    text-shadow: 2px 2px 5px black;
}

.slide-button {
    text-decoration: none;
    padding: 15px 25px;
    border-radius: 3rem;
    transition: 0.25s ease;
    font-size: 1.5rem;
    font-weight: 600;
    white-space: nowrap;
}

.slide-button:focus {
    outline-offset: 0.3rem;
}

.slide-content.left .slide-button {
    margin-left: -0.6rem;
}
.slide-content.right .slide-button {
    margin-right: -0.6rem;
}

.slide-button:hover {
    text-decoration: none;
    transition: 0.25s ease;
}

.slide-button:focus {
    outline-offset: 10px;
}

/* Styling for the navigation arrows */
.hero-carousel .slick-prev,
.hero-carousel .slick-next {
    font-size: 0; /* Hide the default arrows */
    z-index: 10; /* Ensure arrows are above other content */
}

/* Custom arrow icons */
.hero-carousel .slick-prev:before,
.hero-carousel .slick-next:before {
    font-size: 1.5rem; /* Size of custom arrow icons */
    color: #b3b3b3; /* Default color for arrows */
}



.hero-carousel .slick-arrow:focus:before {
    color: #e4a906 !important; /* New color for arrows on hover or focus */
    outline: auto;
    outline-offset: 5px;
}

/* Positioning the navigation arrows */
.hero-carousel .slick-prev {
    left: 25px; /* Position from the left edge */
    border: unset !important;
    outline: unset !important;
}

.hero-carousel .slick-next {
    right: 25px; /* Position from the right edge */
}

/* Styling for the navigation dots */
.hero-carousel .slick-dots {
    top: 2%;
    bottom: 100%; /* Position from the bottom */
    justify-content: center;
}

.hero-carousel .slick-dots li button {
    font-size: 0; /* Hide the default dots */
    line-height: 0; /* Remove line height */
}

/* Custom styling for the dots */
.hero-carousel .slick-dots li button:before {
    font-size: 12px; /* Size of the dot */
    color: #fff; /* Default color for dots */
}

/* Ensure the active dot is visible and styled */
.hero-carousel .slick-dots li.slick-active button:before {
    opacity: 1; /* Full visibility */
    color: #e4a906 !important;
}

.hero-carousel .slick-dots li button:focus:before {
    outline: 2px solid white; /* New color for dots on hover or focus */
}



/* Media queries for smaller screens */
@media screen and (max-width: 768px) {
    .slide-content {
        max-width: 65%; /* Increase the content width on smaller screens */
        width: fit-content;
        background-color: rgba(124, 124, 124, 0.329);
        text-shadow: 3px 3px 8px black;
        max-height: 100%;
        text-align: center;
        padding: 1rem;
        padding-bottom: 2rem;
        backdrop-filter: blur(8px);
    }

    .slide-content h1 {
        font-size: 2rem; /* Adjust the heading font size */
    }

    .slide-content p {
        font-size: 1.2rem; /* Adjust the paragraph font size */
    }

    .slide-button {
        font-size: 1.4rem; /* Adjust the button font size */
        padding: 13px 26px;
        text-shadow: none;
        margin-bottom: 2rem;
    }

    .hero-carousel .slick-slide img {
        min-height: 40vh;
    }
    
}

@media screen and (max-width: 500px) {
    .slide-content h1 {
        font-size: 1.4rem; /* Adjust the heading font size */
    }

    .slide-content p {
        font-size: 0.90rem; /* Adjust the paragraph font size */
        word-wrap: break-word;
    }

    .slide-button {
        font-size: 1.2rem; /* Adjust the button font size */
        padding: 10px 23px;
    }
    .hero-carousel .slick-slide {
        position: relative;
        min-height: 55vh; /* Ensure the slides have a minimum height */
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero-carousel .skeleton-wrapper {
        width: 100%;
        height: 55vh;
    }
    .hero-carousel .slick-slide img {
        object-fit: cover;
    }
}
