.add-user-btn {
    background-color: white;
    color: black; /* Change this color to fit your design */
    padding: 10px 20px;
    font-size: 1.2rem;
    border: none;

    cursor: pointer;
    transition: background-color 0.22s ease;
}

.logout-user-btn {
    background-color: #f44336; /* Red for logout */
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    margin-right: 5px;
    transition: background-color 0.3s ease;
}

.logout-user-btn:hover {
    background-color: #d32f2f; /* Darker red on hover */
}

.add-user-btn:hover {
    background-color: #bebebe; /* Change hover color as needed */
}

.users-table {
    margin: 0 auto;
    margin-bottom: 3rem;
    width: auto;
    border-collapse: collapse;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.users-table thead {
    background-color: #022852;
    color: white;
}

.users-table thead th.actions-column {
    width: 120px;
}

.users-table tbody tr {
    background-color: white;
    color: #022852;
    line-height: 2.82em;
    position: relative;
    transition: 0.2s ease;
}

.users-table tbody td {
    padding: 10px;
}

.users-table tbody tr:hover {
    background-color: rgb(236, 236, 236);
    transition: 0.2s ease;
}

.actions {
    text-align: right;
    padding-right: 10px;
}

.edit-user-btn,
.delete-user-btn {
    margin-left: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.65rem;
    border-radius: 0.4rem;
    transition: 0.2s ease;
}

.edit-user-btn:hover {
    background-color: #bebebe88;
}

.delete-user-btn:hover {
    background-color: rgb(190, 0, 0);
}

.delete-user-btn {
    color: white;
    background-color: red;
    padding-top: 0.2rem;
}

.users-table tbody tr:hover .actions {
    display: block;
}
