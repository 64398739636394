.footer {
    background-color: rgb(1, 0, 29);
    color: white;
    font-family: 'Arial', sans-serif;
    padding-top: 11rem;
    padding-bottom: 3rem;
  }
  
  .footer-cta {
    display: flex;
    flex-direction: row;

    margin-bottom: 30px;
    padding-bottom: 2rem;

    margin-left: 15%;
    margin-right: 15%;
    justify-content: space-between;

  }
  
  .footer-cta-text h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
    width: 80%
  }
  
  .footer-cta-text p {
    font-size: 1.2em;
  }

  .footer-cta-text{
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .footer-cta-text span{
    color: #e4a906;
  }
  
  .footer-cta-button {
    align-items: center;
    display: flex;
  }

  .footer-button {
    background-color: #e4a906; /* Replace with the actual color */
    color: #022852;
    padding: 0.8rem 2rem;
    text-decoration: none;
    border-radius: 2rem;
    font-size: 1.1em;
    font-weight: bolder;
    transition: 0.3s ease;

    &:focus {
      outline-offset: 8px;
    }
  }

  .footer-button:hover {
    background-color: #022852; /* Replace with the actual color */
    color: #e4a906;
    padding: 0.8rem 2rem;
    text-decoration: none;
    border-radius: 2rem;
    font-size: 1.1em;
    font-weight: bolder;
    transition: 0.3s ease
  }
  
  .footer-divider {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
    margin-bottom: 30px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    padding: 0 15%;
  }
  
  .footer-column {
    flex-basis: 24%;
  }
  
  
  .footer-heading {
    font-size: 1.2em;
    margin-bottom: 15px;
  }
  
  .footer-phone {
    font-size: 1em;
    word-wrap: break-word;
    word-break: break-all;
  }
  


  .footer-column.footer-nav ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column.footer-nav li {
    margin-bottom: 17px;
  }

  .footer-column.footer-nav {
    text-align: right;
  }

  .footer-column.footer-contact {
    text-align: left;

    a {
      color: inherit;
      width: fit-content !important;
      transition: color 0.3s ease;

      &:hover {
        color:#e4a906
      }
    }
  }

  .footer-column.footer-nav .footer-heading {
    margin-bottom: 12px;
  }

  .footer-column * {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
  }

  .footer-contact address {
    margin-top: 0.4rem;
    margin-bottom: 0.6rem;
  }

  .footer-contact address iframe {
    border: 0;
    width: 80%;
  }
  
  .mapContainer {
    position: relative;
    width: 100%;
    padding-bottom: 45%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
  }
  
  .mapContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .footer-center-text {
    flex-basis: 40%;
    text-align: center;
    align-self: center;
    padding: 0 20px;
  }

  .to-top-button-wrapper {
    display: flex;
    justify-content: center;
  }

  
  .footer-center-text  span{
    color: #e4a906;
  }



  .footer-nav ul li a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-nav ul li a:hover {
    color: #e4a906;
  }

  .footerIcon {
    font-size: 1.2rem;
    color:white;
    transition: color 0.3s ease;
  }

  .footerIcon:hover {
    font-size: 1.2rem;
    color: #e4a906;

  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 0.8em;
}

.footer-copy, .footer-policy {
    flex-basis: 20%; /* Match the width of the footer columns */
    text-align: center; /* Center the content */
    margin: 0 10.5%; /* Auto margins for horizontal centering */
}


  .footer-policy a {
    color: white;
    text-decoration: none;
  }
  
  .footer-policy a:hover {
    color: #e4a906;
  }
  
  .footer-social {
    display: flex;
  }
  
  .footer-social a {
    color: white;
    padding: 0 5px;
  }
  
  .footer-social a:hover {
    color: #e4a906;
  }

  .back-to-top-button {
    transition: color 0.15s ease;
    cursor: pointer;
    width: fit-content;
    display: flex;
    flex-direction: column;

    i {
      text-decoration: none;
      transition: color 0.15s ease;
      font-size: larger;
    }

    span {
      color: white;
      text-decoration: underline;
      transition: color 0.15s ease;
      margin-bottom: 0.5rem;
    }
  }

  .back-to-top-button:hover,
  .back-to-top-button:focus {
      transition: color 0.15s ease;
      
      i {
        transition: color 0.15s ease;
      }

      span {
        color:#e4a906;
        transition: color 0.15s ease;
      }
  }

  .back-to-top-button .fa-square-caret-up {
    transition: color 0.2s ease, transform 0.2s ease
  }

  .back-to-top-button:hover .fa-square-caret-up,
  .back-to-top-button:focus .fa-square-caret-up{
    transition: color 0.2s ease, transform 0.2s ease;
    color: #e4a906b9;
  }

  .footer-column.footer-nav ul:last-child li:last-child {
    display: flex;
    justify-content: flex-end;
  }


  .tos-modal-toggle {
    font-size: 0.9rem;
    cursor: pointer;
    align-self: right;
    transition: color 0.3s ease;
    justify-self: flex-end;
    width: 60%;
  }

  .tos-modal-toggle:hover {
    font-size: 0.9rem;
    cursor: pointer;
    color: #e4a906;
  }


  .bottom-arrow-keys {
    display: flex;
    gap: 0.2rem;
    justify-content: center;
  }

  .social-icons {
    display: flex;
    width: 100%;
    gap: 0.5rem;
    font-size: larger;
    justify-content: flex-start;

    a {
      color: white;
      transition: color 0.15s ease;
    }
  }

  .social-icons a:hover{
    color: #e4a906;
    transition: color 0.15s ease;
  }
  
  @media (max-width: 768px) {

    .footer-center-text {
      order: 1;
      flex-basis: 100%;
    }
    .footer-bottom {
        justify-content: center;
    }

    .footer-column * {
      text-align: center;
    }

    .footer-contact p{
      display: flex !important;
      justify-content: center !important;
    }



    .footer-copy, .footer-policy {
        flex-basis: 100%; /* Full width on smaller screens */
    }
    
    .footer-cta {
      flex-direction: column;

    }

    .footer-contact address iframe {
      margin-left: 10%;
      margin-right: 10%;
    }
    

    .footer-cta-text {
      text-align: center;
    }

    .footer-cta-text #footer-heading {
      width: 100%
    }
  
    .footer-cta-button {
      margin-top: 20px;
      justify-content: center;
    }
  
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-column {
      margin-bottom: 20px;
    }
  
    .footer-bottom {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-policy {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  
    .footer-social {
      order: -1;
    }

    .social-icons {
      justify-content: center;

      a {
        margin: 0
      }
    }

    .back-to-top-button .fa-square-caret-up {
      color: #e4a906
    }

    .tos-modal-toggle {
      align-self: unset;
      justify-self: unset;
      width: unset;
    }
  

    .footer-column.footer-nav ul:last-child li:last-child {
      display: unset;
      justify-content: unset;
    }

    .footer-heading:nth-child(3) {
      margin-top: 1.5rem;
    }
  }