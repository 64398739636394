
.add-slide-btn {
    background-color: white;
    color: black; /* Change this color to fit your design */
    padding: 10px 20px;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    margin-bottom: 20px; /* Adds some space below the button */
    transition: background-color 0.22s ease;
}

.add-slide-btn:hover {
    background-color: #bebebe;
}

.slides-admin .admin-header {
  margin-left: 15%;
  margin-right: 15%;
}