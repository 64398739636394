.section-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.section-separator {
    width: 100%;
    display: flex;
}

.section-separator * {
    transition: background-color 0.3s ease;
}

.about-separator-step-1 {
    height: 0px;
    background-color: rgb(245, 245, 245);
    z-index: 80;
    flex-grow: 1;
    flex: 1;
    width: 100%;
}

.about-separator-step-2 {
    height: 0px;
    background-color: rgb(245, 245, 245);
    z-index: 80;
    flex-grow: 1;
    flex: 1;
    width: 100%;
    margin-top: -2rem;
}

#dark .about-separator-step-3 {
    background-color: rgba(1, 0, 29, 0.644);
    p {
        color: white;
    }
}

#dark .about-separator-step-3:focus,
#dark .about-separator-step-3:hover {
    background-color: rgb(1, 0, 29);
    p {
        color: #e4a906;
        text-shadow: 0px 0px 10px #e4a906c0;
    }
}

.about-separator-step-3 {
    height: 64px;
    background-color: rgba(245, 245, 245, 0.685);
    z-index: 4;
    flex-grow: 1;
    flex: 1;
    width: 60%;
    margin-top: -2rem;
    margin-left: 20%;
    margin-right: 20%;
    display: flex;
    flex-direction: column;
    transition: 0.3s ease;
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
    cursor: pointer;

    p {
        color:#022852;
        text-shadow: 0px 0px 10px #022852;
        transition: 0.3s ease;
        margin-top: 0.5rem;
    }

    &:focus {
        outline-offset: 5px;
    }
}

#light .about-separator-step-3:hover,
#light .about-separator-step-3:focus {
    
    background-color: rgba(245, 245, 245);
    p {
        color: #e4a906;
        text-shadow: 0px 0px 10px #e4a906c0; 
    }
    
}

.about-separator-step-3 i {
    transition: transform 0.3s ease;
    font-size: 1.5rem;
}

.about-separator-step-3:hover i {
    transform: scale(140%);
    transition: transform 0.3s ease;
}

.about-separator-step-4 {
    height: 0px;
    background-color: rgb(245, 245, 245);
    z-index: 80;
    flex-grow: 1;
    flex: 1;
    width: 100%;
}

.about-separator-step-5 {
    height: 0px;
    background-color: rgb(245, 245, 245);
    z-index: 80;
    flex-grow: 1;
    flex: 1;
    width: 100%;
}

#dark .benefits-separator-step-1 {
    background-color: rgb(1, 0, 29) !important;
}

#dark .benefits-separator-step-4 {
    background-color: rgb(1, 0, 29) !important;
}

.benefits-separator-step-1 {
    height: 64px;
    background-color: rgb(245, 245, 245);
    z-index: 5;
    flex-grow: 1;
    flex: 1;
    width: 100%;
    margin-bottom: -5rem;
}

.benefits-separator-step-2 {
    height: 0px;
    background-color: rgb(129, 134, 147);
    z-index: 5;
    flex-grow: 1;
    flex: 1;
    width: 100%;
    margin-bottom: -5rem;
}

.benefits-separator-step-3 {
    height: 0px;
    background-color: rgb(129, 134, 147);
    z-index: 5;
    flex-grow: 1;
    flex: 1;
    width: 100%;
    margin-bottom: -5rem;
}

.benefits-separator-step-4 {
    height: 64px;
    background-color: rgb(245, 245, 245);
    z-index: 5;
    flex-grow: 1;
    flex: 1;
    width: 100%;
    margin-bottom: -5rem;
}


#dark .services-separator-step-2,
#dark .services-separator-step-3,
#dark .services-separator-step-4 {
    background-color: rgb(73, 76, 83);
}

.services-separator-step-1 {
    height: 0px;
    background-color: rgb(245, 245, 245);
    z-index: 5;
    flex-grow: 1;
    flex: 1;
    width: 100%;
    margin-bottom: -4rem;
}

.services-separator-step-2 {
    height: 21px;
    background-color: rgb(129, 134, 147);
    z-index: 5;
    flex-grow: 1;
    flex: 1;
    width: 100%;
    margin-bottom: -4rem;
}

.services-separator-step-3 {
    height: 42px;
    background-color: rgb(129, 134, 147);
    z-index: 5;
    flex-grow: 1;
    flex: 1;
    width: 100%;
    margin-bottom: -4rem;
}

.services-separator-step-4 {
    height: 64px;
    background-color: rgb(129, 134, 147);
    z-index: 5;
    flex-grow: 1;
    flex: 1;
    width: 100%;
    margin-bottom: -4rem;
}

.section-separator.services-separator {
    margin-top: -0.4rem;
}

section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 8rem;
    padding-bottom: 4rem;
    width: 100%;
    min-height: 30rem;
    text-align: center;
}

.hero {
    display: unset;
    padding: 0; /*For carousel*/
    position: relative;
    height: 80vh;
    z-index: 2;
    opacity: 1;
    transform: translateY(0);
    margin-bottom: -2rem;
}

.about {
    background-color: inherit;
    padding: 7rem 0rem;
    text-align: left;
}

.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    flex-wrap: wrap; /* Allow items to wrap in smaller screens */
    max-width: 70%; /* Set a maximum width for larger screens */
    margin-left: 15%;
    margin-right: 15%;
}

.about-logo {
    flex: 1;
    max-width: 50%; /* Limit logo size to 50% of the container width on larger screens */
    transition: transform 2s ease, padding 2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
}

.about-image.scale-up {
    transform: scale(1.07); /* Adjust the scale as needed */
    transition: transform 2s ease 0.1s; /* Delay the start of the scale effect */
    will-change: transform;
  }

.about-image:hover {
    transform: scale(1.17);
    transition: 2s ease;
    will-change: transform;
}

.about-content {
    flex: 2; /* Take up twice the space as the logo */
    max-width: 100%; /* Ensure it takes up available space */
    padding: 1rem; /* Add padding for spacing */
}

.about-content h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.about-content p {
    font-size: 1.2rem;
    line-height: 1.6;
}

.about-content p span{
    color: #e4a906
}



.services {
    background-color: #e8e8e8;
    color: #333;
    padding-bottom: 7rem;
}


.testimonials {
    background-color: #d7d7d7;
    color: #333;
}

.contactSection {
    background-color: #c6c6c6;
    color: #333;
}

h1, h2 {
    margin: 0 0 20px;
    font-weight: 300;
}

p {
    font-size: 18px;
    line-height: 1.6;
}


.services {
    position: relative;
    background-image: url('../../assets/images/servicesBG.jpg');
    background-size: cover;
    background-position: center;
}

#dark .overlay {
    background-color: rgba(0, 2, 19, 0.82); 
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 2, 19, 0.75); /* Services section overlay opacity */
    z-index: 1;
}

.services-section {
    position: relative;
    z-index: 2;
}


@keyframes SlideUp {
    0% {
        transform: translateY(7rem)
    }
    100% {
        transform: translateY(0);
    }
}


.about-logo.slide-up,
.about-content.slide-up {
    opacity: 1;
    transform: translateY(0);
    animation: SlideUp 0.5s ease-out forwards; /* Adding the animation */
}

#dark .about-logo svg .lumens{
    filter: drop-shadow(0 0 33px currentColor);
}


#dark .about-logo svg .www{
    filter: drop-shadow(0 0 65px currentColor);
}

.about-logo,
.about-content {
    opacity: 0;
    transform: translateY(7rem); /* Start with the same transform as the initial state in the keyframes */
    transition: opacity 0.3s ease-out, transform 0.3s ease-out; /* Ensure a smooth transition */
}

.benefits {
    background-color: rgb(129, 134, 147);
    transition: background-color 0.3s ease;
}


.homePageFooter {
    position: relative;
    z-index: 2;
}
  
.homePageFooter::before {
    content: '';
    position: absolute;
    top: 15rem;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #000);
    z-index: -1;
}
  

.services {
    position: relative;
    z-index: 1; /* Lower than the z-index of .homePageFooter */
}
  
.services::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 15rem; /* Same as the top value in homePageFooter */
    background: linear-gradient(to bottom, transparent, rgb(1, 0, 29)); 
    z-index: 2;
}


@media (max-width: 768px) {
    .wrapper {
        padding: 0 10px;
    }

    h1, h2 {
        font-size: 24px;
    }

    p {
        font-size: 16px;
    }
}

@media (max-width: 1060px) {
    .about {
        text-align: center;
    }

    .about-container {
        flex-direction: column;
        align-items: center; /* Center the items */
        text-align: center;
    }

    .about-logo,
    .about-content {
        max-width: 100%; /* Make logo and content full-width */
    }

    .about-logo {
        padding-bottom: 1.5rem;
    }
}

@media (max-width: 768px) {
    .about-logo {
        transform: scale(0.75);
    }
    .about-separator-step-3 {
        height: 45px;
        margin-top: 0;
    }
    .about-separator-step-3 p {
        scale: 1.1;
    }
    .about-separator-step-3 p span {
        display: none;
    }
}

@media (max-width: 600px) {
    .about-logo {
        transform: scale(0.6);
    }
}

@media (max-width: 450px) {
    .about-logo {
        transform: scale(0.5);
    }
}

@media (max-width: 350px) {
    .about-logo {
        transform: scale(0.4);
    }
}

@media (max-width: 225px) {
    .about-logo {
        transform: scale(0.3);
    }
}

