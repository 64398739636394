.add-blog-btn {
    background-color: white;
    color: black; /* Change this color to fit your design */
    padding: 10px 20px;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.22s ease;
}

.sitemap-status {
    font-weight: bold;
    margin-top: 10px;
}

.sitemap-status.present {
    color: green;
}

.sitemap-status.absent {
    color: red;
}

.add-blog-btn:hover {
    background-color: #bebebe; /* Change hover color as needed */
}
.blogs-list {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    margin-left: 15%;
    margin-right: 15%;
    justify-content: center;
    padding-bottom: 4rem;
}

#dark .blog-entry {
    background-color: inherit;
}

.blog-entry {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 1.5rem;
    background-color: #f9f9f9; /* Light background for each blog entry */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    word-wrap: break-word;
    width: 35%;
    min-width: 14rem;
}

.blog-entry img {
    max-height: 11rem;
}

.edit-btn, .delete-btn {
    margin-top: 10px;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    border-radius: 3px; /* Rounded corners */
    margin-right: 5px; /* Space between buttons */
}

.delete-btn {
    background-color: #f44336; /* Red background for delete button */
    color: white;
}

.blog-entry .edit-btn {
    background-color: #e2e2e2; /* Green background for edit button */
    color: black;
}

.blog-entry .blog-date {
    text-align: center; /* Center the date */
    color: #5a5a5a;
    font-weight: 600; /* Lighter text color for the date */
    margin-bottom: 5px; /* Spacing between the date and the title */
    font-size: 1.05rem; /* Slightly smaller font size for the date */
}

/* Adjustments for better responsiveness */
@media (max-width: 768px) {
    .admin-page {
        padding: 10px;
    }
    .add-blog-btn, .edit-btn, .delete-btn {
        font-size: 1rem; /* Smaller font size on smaller screens */
        padding: 8px 16px; /* Adjust padding */
    }
}
