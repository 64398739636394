#dark .blog-post {
    background-color: #02275263;
    color: white
}

.blog-post {
    background-color: #f9f9f9c2;
    border-radius: 1rem;
    flex-grow: 1;
    text-align: center;
    transition: all 0.25s ease;
    color: #022852;
    overflow: hidden; /* Prevents text from spilling out */
    will-change: transform;
    text-decoration: none; /* Ensure this is set */
    max-width: 100%;
    flex-basis: 45% !important;
    padding: 1rem 1rem 1rem 1rem;
    border: 1px solid rgba(119, 119, 119, 0.205);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.blog-post:hover {
    transform: translateY(-1%);
    transition: 0.25s ease;
    will-change: transform;
    background-color: #ececec;
}


.blog-post .blogcard-categories .blog-post-categories .blog-category {
    justify-content: center;
}

.blog-post:hover .blogcard-categories .blog-post-categories .blog-category {
    background-color: #e0e0e0 !important;
    transition: 0.25s ease;
}

.blog-post a, .blog-post a:hover {
    text-decoration: none; /* Remove underlines on normal and hover states */
    color: inherit; /* Optional: Ensures the text color is consistent */
}

.blog-post:hover h3 {
    text-decoration: underline;
    color: #e4a906;
    transition: 0.15s ease;

}

.blog-post img.blog-image {
    width: 100%; /* Full width within the card */
    height: 14rem;
    border-radius: 1rem;
    object-fit: cover;
    margin-top: 0;
    display: none;
}

.blog-post:first-child img.blog-image {
    display: block; /* Show the image for the first blog post */
}

.blog-post h3 {
    margin-top: 0;
    text-decoration: none;
    transition: 0.15s ease;
    word-wrap: break-word;
    font-size: 2.4rem;
    font-weight: 700;
    padding-top: 20px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-block-end: 0.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#dark .blog-post time {
    color: #949494;
}

.blog-post time {
    font-size: 0.9rem;
    color: #565656;
    text-decoration: none !important;
}

#dark .blog-post p {
    color: #afafaf;
}

.blog-post p {
    line-height: 1.6;
    text-decoration: none;
    word-wrap: break-word;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    font-size: 1.2rem;
    color: #444444;
}

.blog-post a i.fa-solid {
    position: absolute;
    top: 50%;
    right: 15px; 
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.2s ease-in-out; 
}

.blogcard-categories .blog-post-categories{
    width: 100%;
    justify-content: center;
    gap: 1rem;
    display: flex;
}


#dark .blogcard-categories .blog-post-categories .blog-category {
    background-color: transparent !important;
    color: #e4a906;
    outline: 1px solid #afafaf;
}

.blogcard-categories .blog-post-categories .blog-category {
    transition: 0.25s ease;
    background-color: #efefef !important;
}

.blog-post.featured {
    flex-basis: 100% !important;
    max-width: none;
}

@media (max-width: 550px) {
    
    .blog-post {
        max-width: 100%;
    }
}
