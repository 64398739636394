.add-blog-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .add-blog-form {
    padding: 20px; /* Adds padding inside the form */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center; /* This will center the content */
    width: 50%;
    color: black;
  }
  
  .add-blog-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px; /* Adds space between form fields */
  }
  
  .add-blog-form form > div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .add-blog-form label {
    text-align: left; /* Aligns the label text to the left */
    margin-bottom: 5px; /* Space below the label */
  }
  
  .add-blog-form input[type="text"],
  .add-blog-form input[type="file"] {
    padding: 15px; /* Increases padding for larger field height */
    font-size: 1rem; /* Bigger text for better readability */
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-blog-form .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%; /* Match width with other inputs */
    margin-top: 0.75rem;
  }
  
  .add-blog-form .tag {
    background-color: #e4e4e4; /* Light grey background */
    border-radius: 20px; /* Rounded edges */
    padding: 5px 10px; /* Padding inside the tag */
    display: inline-flex; /* Inline-flex to keep it inline and allow flex properties */
    align-items: center; /* Vertically center content */
    gap: 5px; /* Space between the text and the delete button */
  }
  
  .add-blog-form .tag button {
    background: red;
    color: white;
    border: none;
    cursor: pointer;
    padding: 0 5px; /* Smaller padding for the button */
    display: inline-block; /* Ensure it doesn't stretch */
    font-size: 0.8rem; /* Smaller font size for the button */
  }
  
  .add-blog-form button[type="submit"] {
    width: 100%; /* Adjust width of the submit button as needed */
    padding: 12px 24px; /* Larger button for bigger text */
    font-size: 1.3rem; /* Bigger text on the button */
    border-radius: 5px;
    background-color: #022852;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .add-blog-form button[type="submit"]:hover {
    background-color: #e4a906;
  }
  
  @media (max-width: 768px) {
    .add-blog-form {
      width: 95%; /* Adjusts form width on smaller screens */
      padding: 15px;
    }
  
    .add-blog-form form > div {
      width: 100%; /* Ensures inputs take full width in mobile view */
    }
  }
  

  .formButtons {
    width: 100%;
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
  }

.cancelEdit {
    background-color: #f44336; /* Red background for delete button */
    color: white;
    padding: 10px 20px;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.22s ease;
    margin-top: 40px;
}

.cancelEdit:hover {
    background-color: #bebebe; /* Change hover color as needed */
}