/* Define the initial state and the animation */
@keyframes SlideUp {
  0% {
    transform: translateY(7rem);
  }
  100% {
    transform: translateY(0);
  }
}

/* Apply the slide-up animation to sections on the About page */
.about-page section {
  opacity: 0;
  transform: translateY(8rem);
  transition: all 1s ease-in-out;
}

/* Define the active slide-up state */
.about-page .slide-up {
  opacity: 1 !important;
  transform: translateY(0);
}

/* Define the image scale-up effect */
.about-page img {
  transition: transform 0.5s ease;
}

.about-page .scale-up {
  transform: scale(1.05);
}

/* Specific styles for the about page sections */
.text-content h2 {
  color: #e4a906;
  font-size: 2.5rem;
  text-shadow: 2px 3px 4px #181301;
}

.text-content p {
  color: white;
  font-size: 1.3rem;
}

.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-background {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

#dark .section-background.blue {
  background-color: #021f41;
}

.section-background.blue {
  background-color: #022852;
  padding-bottom: 7rem;
}

.section-background.blue.top {
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  padding-top: 7rem;
  box-shadow: 0 -4px 5px -1px rgba(0, 11, 71, 0.849);
}

#dark .section-background.grey {
  background-color: rgb(73, 76, 83);;
}

.section-background.grey {
  background-color: rgb(129, 134, 147);
  flex-direction: column;
  padding-top: 7rem;
}

.mission-section,
.motivation-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-sizing: border-box;
  z-index: 1; 
  margin-left: 10%;
  margin-right: 10%;
}

.mission-section .text-content,
.motivation-section .text-content {
  flex-basis: 42%;
  padding: 1rem; 
}

.mission-section .image-content img,
.motivation-section .image-content img {
  max-width: 100%;
  height: 28rem;
  object-fit: contain;
  transition: 2s ease;
  will-change: transform;
}

.image-content img.scale-up {
  transform: scale(1.07);
  transition: transform 2s ease 0.6s;
  will-change: transform;
}


  .title-text{
    font-size: 2.5rem;
    font-size: 2.5rem;
    font-family: inherit;
    font-weight: 600;
    text-shadow: 3px 3px 7px rgba(0, 0, 0, 0.5);
  }
  

  .mission-section {
    flex-direction: row;
  }
  
  .mission-section .text-content {
    padding-right: 2rem;
  }
  

  .motivation-section {
    flex-direction: row;
  }
  
  .motivation-section .text-content {
    padding-right: 2rem;
  }
  
  .motivation-section .image-content {
    padding-left: 2rem; 
  }
  

  .contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    padding-top: 2rem;

  }
  
#contact-heading {
  font-size: 2rem;
  color: #e4a906;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


.contact-methods {
    display: flex;
    justify-content: center;
    gap: 4rem;
    margin-bottom: 2rem;
}

.contact-method {
    text-align: center;
    padding: 1rem;
    background-color: #022852;
    width: 16rem;
    color: white;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25),
    -2px 6px 8px rgba(0, 0, 0, 0.4);
    transition: transform 1s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.85s ease;
    will-change: transform, box-shadow;
}

.contact-method:hover {
  box-shadow: 4px 5px 5px rgba(0, 0, 0, 0.4),
  -4px 8px 6px rgba(0, 0, 0, 0.45);
  transition: transform 1s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.85s ease;
  transform: scale(1.035);
  will-change: transform, box-shadow;
}


.contact-method h3 {
    margin-bottom: 0.5rem;
    will-change: transform, color;
}

.contact-method p {
    margin-bottom: 1rem;
    text-decoration: underline;
    color: #e4a906;
    transition: 0.2s ease;
    will-change: transform;
}

.contact-method i {
    font-size: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 3rem;
    color: white;
    transition: 0.2s ease;
    will-change: color, transform;
}

.contact-method a {
    display: block;
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: 0.2s ease;
    will-change: color, transform;
    span {
      color: #e4a906;
      transition: 0.2s ease;
    }
}



.contact-method a:hover {
    color: #be8c02;
    transition: color 0.2s ease;
    will-change: color;
    i {
        color: #be8c02;
        transition: color 0.2s ease;
    }

    p {
      color: #be8c02;
      transition: 0.2s ease;
    }
    span {
      color: #be8c02;
      transition: 0.2s ease;
    }
}


.or-divider {
    position: relative;
    width: 100%;
    text-align: center;
    margin: 2rem 0;
    margin-top: 0;
    
}

.or-divider:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 95%;
    height: 1px;
    background-color: #ccc;
    z-index: 1;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.or-text {
    position: relative;
    background-color: #022852; 
    display: inline-block;
    padding: 0 1rem;
    color: #e4a906; 
    font-size: 1.5rem;
    z-index: 2;
    border-radius: 1rem;
}

.contact-form h2{
  font-size: 1.5rem;
  color: white;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.589);
  text-decoration: underline;
}

.in-person-meeting{
  font-size: 0.88rem;
  color: rgb(250, 250, 250);
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.589);
}

.contact-form span{
  color: #e4a906;
}


@media (max-width: 1350px) {
    .contact-form {
      margin-left: 6%;
      margin-right: 6%;
    }


    .mission-section,
    .motivation-section {
      flex-direction: column;
    }
  
    .mission-section .text-content,
    .motivation-section .text-content,
    .mission-section .image-content,
    .motivation-section .image-content {
      flex-basis: 100%;
      padding: 0;
    }
  
    
    .mission-section .image-content img,
    .motivation-section .image-content img {
      max-width: 85%;
      height: auto;
      margin-bottom: 2rem;
    }
  
    .mission-section .text-content,
    .motivation-section .text-content {
      order: 2;

      h2 {
        font-size: 2rem;
        word-wrap: break-word;
      }
    }
  
    .mission-section .image-content,
    .motivation-section .image-content {
      order: -1; /* Image goes above text on small screens */
      margin-bottom: 1rem;
    }
  
    .section-background {
      padding: 0;
    }

    .contact-methods {
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;

    }


}

@media (max-width: 640px) {

  .contact-methods {
    gap: 0.3rem
  }

  .contact-method i {
    font-size: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0rem;
    height: unset;
    color: white;
    transition: 0.2s ease;

  }

    .contact-method {
      padding: 0.2rem;
      max-width: 46% !important;

      flex-shrink: 0.4;
    }
    .contact-method > * {
      word-wrap: break-word;
      word-break: break-all;
    }

    .contact-method a {
      font-size: 0.8rem !important;

      p {
        font-size: 0.60rem !important;

      }
    }
}


@media (max-width: 300px) {

  .contact-methods {
    flex-direction: column;
  }
  .contact-method {
    max-width: unset !important;
    width: 100% !important;
  }
}