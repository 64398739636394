/* RecentBlogs.css updates */
.blog-title {
  font-size: 1rem; /* Make titles larger */
  font-weight: bold; /* Optional: Make the titles bold for emphasis */
  word-wrap: break-word;
}

#dark .blog-date {
  color: #afafaf;
}

.blog-date {
  font-size: 0.8rem; /* Make dates smaller */
  margin-left: 10px;
  color: #6d6d6d;
  font-style: italic;
  min-width: 2.5rem;
  will-change: transform;
}

.more-blogs-btn {
  color: #022852;
  font-size: 1.2rem;
  transition: color 0.25s ease;
}

.more-blogs-btn:hover {
  color: #e4a906;
  font-size: 1.2rem;
  transition: color 0.25s ease;
}

.blogSidebar-container.recentBlogs ul {
  text-align: left;
  list-style: none;
  padding: 0;
}

.blogSidebar-container.recentBlogs li {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensure vertical alignment */
  margin-bottom: 10px; /* Add margin to space out list items */
}

.skeleton-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skeleton-title {
  width: 70%; /* Ensure width consistency */
}

.skeleton-date {
  width: 30%; /* Ensure width consistency */
}
