.service-card {
    max-width: 15rem;
    padding: 1.8rem;
    background-color: rgba(211, 211, 211, 0.055);
    color: white;
    text-shadow: 0px 0px 2px #b3b3b3;
    margin: 1.1rem;
    border-radius: 10px;
    transition: 0.44s ease;
    outline: 2px solid transparent;
    box-shadow: 0 6px 20px transparent;
    will-change: background-color, transform, text-shadow, box-shadow, color, outline;
    backdrop-filter: blur(1px);
    
}

.service-card:hover {
    
    backdrop-filter: blur(4px);
    color: #e4a906;
    text-shadow: 1px 1px 2px transparent;
    transition: 0.44s ease;
    outline-color: transparent;
    font-size: inherit;
    font-weight: inherit;
    transform: scale(1.03);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.55);
    will-change: background-color, transform, text-shadow, box-shadow, color, outline, backdrop-filter;
}

.service-card:hover  {
    text-shadow: 0px 0px 15px #e4a906a9;
}

.icon-container {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease;
}

.service-card:hover .icon-circle-container .icon-circle {
    fill: #02275248;
    transition: fill 0.5s ease;
}
  
.service-card:hover .icon-circle-container .icon-graphics {
    stroke: #e4a906;
    transition: stroke 0.44s ease, fill 0.44s ease, box-shadow 0.44s ease;
    box-shadow: 
        0 0 10px #e4a906,   /* First layer of glow */
        0 0 20px #e4a906,   /* Second layer of glow */
        0 0 40px #e4a906,   /* Third layer of glow */
        0 0 70px #e4a906;   /* Fourth layer of glow */
}

  

.icon-circle {
    transition: fill 0.44s ease;
    fill: #0227528f;
}

.icon-graphics {
    transition: stroke 0.7s ease-out, fill 0.7s ease-out;
    stroke: rgb(129, 134, 147);
}


@media (max-width: 768px) {
    .service-card {
        margin-top: 1.1rem;
        margin-bottom: 1.1rem;
        margin-left: 0;
        margin-right: 0;
    }

  }