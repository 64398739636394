.admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    margin-bottom: 1rem;
    margin-left: 15%;
    margin-right: 15%;
    flex-wrap: wrap;
  }

  .home-button i {
    margin-right: 0.4rem;
  }
  
  .home-button, .logout-button {
    background-color: #ffffff;
    color: #022852;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .logout-button {
    background-color: #f44336;
    color: white;
  }

  .logout-button:hover {
    background-color: #c52c2c;
    color: rgb(194, 194, 194);
  }
  
  .home-button:hover {
    background-color: #022852;
    color: #ffffff;
  }
  
  .logout-button i {
    margin-left: 5px;
  }
  