/* Define theme variables for light and dark modes */
#light {
  --background-color: rgb(245, 245, 245);
  --header-background-color: inherit;
  --main-font-color: #000000;
  --selection-background-color: #e4a906;
  --selection-font-color: #022852;
  --page-title-color: white;
  --page-title-bg-overlay: rgba(228, 169, 6, 0.883);
  --box-shadow-color: rgba(0, 0, 0, 0.39);
  --text-shadow-color: rgba(0, 0, 0, 0.5);
}

#dark {
  --background-color: rgb(1, 0, 29);
  --header-background-color: rgb(1, 0, 29);
  --main-font-color: #ffffff;
  --selection-background-color: #e4a906;
  --selection-font-color: #022852;
  --page-title-color: white;
  --page-title-bg-overlay: rgba(228, 169, 6, 0.883);
  --box-shadow-color: transparent;
  --text-shadow-color: rgba(255, 255, 255, 0.3);
}

/* Global styles using theme variables */
:root {
  --main-font-family: 'Montserrat', sans-serif;
  background-color: var(--background-color);
}


.AppWrapper {
  font-family: var(--main-font-family);
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  box-shadow: 4px 0 15px var(--box-shadow-color);
  background-color: var(--background-color);
  color: var(--main-font-color);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}


.menu-open {
  transform: translateX(-60%);
}

/* Header styling */
.App-header {
  background-color: var(--header-background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--main-font-color);
}

/* Selection styles */
::-moz-selection {
  color: var(--selection-font-color);
  background: var(--selection-background-color);
}

::selection {
  color: var(--selection-font-color);
  background: var(--selection-background-color);
}

/* Page title section styling */
.page-title {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 4rem;
  font-size: 2.5rem;
  font-family: inherit;
  font-weight: 600;
  background-size: cover;
  background-position: center;
  color: var(--page-title-color);
  margin-bottom: -1rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  width: 100%;
  box-shadow: 0 -3px 9px -1px var(--box-shadow-color);
  text-shadow: 3px 3px 7px var(--text-shadow-color);
}

.page-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--page-title-bg-overlay);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  z-index: 0;
}

.page-title > * {
  position: relative;
  z-index: 1;
}

.title-text {
  position: relative;
  z-index: 2;
}

header {
  display: flex;
  flex-direction: column;
}

/* Userway buttons positioning */
.userway-buttons-wrapper {
  right: 1 !important;
}
