.blogs-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: #e4a906;
    background-color: #022852;
    border-top: 3px solid #e4a906;
    border-bottom: 3px solid #e4a906;
    padding-bottom: 0.5rem;
}

.category-view .blog-post img{
    display: block !important;
}

.blogs-title {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    font-weight: 1000;
    font-family: monospace;
    white-space: pre;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
}

.blog-description {
    text-align: center;
    color: #e4a906fd;
    margin-top: -1rem;
    font-size: 1.2rem;
    word-wrap: break-word;
    padding: 0 1rem;
    max-width: 100%;
    box-sizing: border-box;
    margin-left: 10%;
    margin-right: 10%;
}

#dark .blog-inputs-wrapper {
    background-color: rgba(1, 0, 29);
}

.blog-inputs-wrapper {
    background-color: rgb(216, 216, 216);
    padding: 1rem 0;
    z-index: 5 !important;
    position: relative;
    box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.39);
}

.search-icon {
    color: inherit;
    right: 0;
    margin-right: 0.5rem;
    position: absolute;
    z-index: 10;
    font-size: 1.4rem;
    transition: 0.4s ease;
}

.blog-search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.blog-inputs {
    position: relative;
    margin-left: 20%;
    margin-right: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    z-index: 5 !important;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
}

#light .blog-inputs input[type="search"], 
#light .blog-inputs select {
    background-color: #f9f9f9c2;
    color-scheme: light;
}

#dark .blog-inputs input[type="search"], 
#dark .blog-inputs select {
    background-color: #022852;
    color-scheme: dark;
}

  
.blog-inputs input[type="search"], .blog-inputs select {
    height: 2.5rem;
    outline: 1px solid rgba(1, 0, 29, 0.801);
    border: 0;
    padding: 0 0.5rem;
    border-radius: 0.5rem;
    transition: outline 0.4s ease, border-radius 0.2s ease, color 0.4s ease, background-color 0.4s ease;
    z-index: 3;
}

.blog-inputs input[type="search"]:hover, .blog-inputs select:hover {
    border: 0;
    transition: outline 0.4s ease, border-radius 0.2s ease, color 0.4s ease, background-color 0.4s ease;
    border-radius: 1.5rem;
}

.blog-inputs input[type="search"]:focus, .blog-inputs select:focus {
    outline: 1px solid #e4a906;
    border: 0;
    transition: outline 0.4s ease, border-radius 0.2s ease, color 0.4s ease, background-color 0.4s ease;
    border-radius: 0rem;
}

.blog-inputs input[type="search"] {
    font-size: 1.1rem;
    width: 100%;
}

.blog-search:focus + .search-icon {
    color: #e4a906; /* Changes icon color to yellow when the input is focused */
    transition: 0.4s ease;
}

.blog-inputs select {
    cursor: pointer;
    width: 100%; /* Make select elements 100% width on smaller screens */
    min-width: fit-content;
}

.tag-cat-selectors {
    display: flex;
    gap: 1rem;
    width: max-content;
}


.blogs-divider {
    border: none;
    height: 2px;
    background: linear-gradient(to right, transparent, #e4a906, transparent);
    margin: 0;
    margin-top: 1rem;
    width: 100%;
}

#dark .blogsWrapper {
    background-color: rgba(1, 0, 29);
}

.blogsWrapper {
    position: relative;
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 5rem;
    height: 100%;
    background-color: rgb(216, 216, 216);
    overflow: hidden;
}


.blogs {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 3rem;
    position: relative;
    z-index: 1;
}

#dark .blogs-tag-title {
    color:#e4a906
}

.blogs-tag-title {
    display: flex;
    text-align: center;
    justify-content: center;
    color: #022852;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 3rem;
    word-wrap: normal;
    flex-wrap: wrap;
    padding: 0.5rem 0rem;

}

.blog-tag-view .blogs .blog-post img {
    display: block !important;
}

#dark .all-categories-link a{
    color: white
}

#dark .all-categories-link a:hover{
    color: #e4a906
}

.all-categories-link {

    a {
        font-size: 1.5rem;
        color: rgba(1, 0, 29);
        transition: color 0.15s ease;
    }

    a:hover {
        color: #e4a906;
        transition: color 0.15s ease;
    }
}

.result-message {
    margin-top: 4rem;
    margin-bottom: 2rem;
}

.blogs-tag-title .tagName{
    text-decoration: underline;
    font-weight: 600;
}

@media (max-width: 1215px) {
    
    .blogs-title pre {
        transform: scale(0.8);
    }
}

@media (max-width: 975px) {
    
    .blogs-title pre {
        transform: scale(0.6);
    }
}



@media (max-width: 740px) {
    .blogs-title pre {
        transform: scale(0.5);
    }
}

@media (max-width: 620px) {

    .blogs-title pre {
        transform: scale(0.4);
    }

}

@media (max-width: 500px) {
    
    .blogs-title pre {
        transform: scale(0.3);
    }
}

@media (max-width: 380px) {
    
    .blogs-title pre {
        transform: scale(0.2);
    }
    .blogs-tag-title {
        font-size: 2.1rem;
    }
    .blogs-tag-title span {
        word-break: normal;
    }
}

@media (max-width: 1050px) {
    .blog-inputs {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 1rem;
        width: 90%; /* Make the container full width */
    }
    .blog-inputs-wrapper {
        padding: 1rem 0;
    }
    .blogs {
        margin-right: 5%;
        margin-left: 5%;
    }
    .blog-inputs {
        margin-right: 5%;
        margin-left: 5%;
    }
    .blog-search-wrapper {
        width: 100%;
    }
    .tag-cat-selectors {
        display: flex;
        gap: 1rem;
        width: 100%;
        min-width: 0;
    }

    .blog-inputs input[type="text"], .blog-inputs select {
        width: 100%
    }
}
