.add-slide-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .add-slide-form {
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
  }
  
  .add-slide-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
  }
  
  .add-slide-form form > div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .add-slide-form label {
    text-align: left;
    margin-bottom: 5px;
  }
  
  .add-slide-form input[type="text"],
  .add-slide-form input[type="file"] {
    padding: 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Adjust the CKEditor specific styles as needed */
  
  .add-slide-form button[type="submit"] {
    width: 100%;
    padding: 12px 24px;
    font-size: 1.3rem;
    border-radius: 5px;
    background-color: #022852;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .add-slide-form button[type="submit"]:hover {
    background-color: #e4a906;
  }
  
  @media (max-width: 768px) {
    .add-slide-form {
      width: 95%;
      padding: 15px;
    }
  
    .add-slide-form form > div {
      width: 100%;
    }
  }
  
  .formButtons {
    width: 100%;
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
  }
  
  .cancelEdit {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.22s ease;
    margin-top: 40px;
  }
  
  .cancelEdit:hover {
    background-color: #bebebe;
  }
  