.inquiries-admin {
    padding-bottom: 4rem;
}

.inquiries-admin .admin-header {
    margin-left: 15%;
    margin-right: 15%;
}

.inquiries-list {
    display: flex;
    gap: 1.5rem;
    margin-left: 15%;
    margin-right: 15%;
    flex-wrap: wrap;
}

.inquiry-entry {
    display: flex;

    perspective: 1000px; /* Necessary for 3D effect */
    min-height: 30rem;
    flex-grow: 1;
    border-radius: 1.5rem;
    flex-basis: 50%;
}

.inquiry-id {
    color: grey
}

.inquiry-contact-method span {
    text-decoration: underline;
}

#dark .card-face {
    background-color: inherit;
}

.card-face {
    position: absolute;
    backface-visibility: hidden;
    transition: transform 0.6s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    overflow-y: auto;
    background-color: #f9f9f9;
    border-radius: 1.5rem;
}

.card-face p {
    text-wrap: wrap;
    word-wrap: break-word;
    width: 95%;
}

.card-back {
    transform: rotateY(180deg);
    height: 100%;
}

.inquiry-description {
    padding-top: 3rem;
}

.flipped .card-front {
    transform: rotateY(-180deg);
}

.flipped .card-back {
    transform: rotateY(0deg);
}

.card-back h3 {
    position: absolute;
    top: 0;
}

.card-back p {
    position: absolute;
    top: 0;
}

.flip-btn {
    color: inherit;
    background-color: inherit;
    border: none;
    position: absolute;
    top: 2%;
    right: 1%;
    cursor: pointer;
    font-size: 2.2rem;
}

.inquiry-submit-time {
    position: absolute;
    top: 0.7rem;
    left: 1rem;
    font-size: 0.9rem;
    color: grey;
    margin: 0; /* Ensure there is no margin */
    padding: 0; /* Ensure there is no padding */
    text-align: left;
    will-change: transform;
}