.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    animation: fadeIn 0.3s ease-in-out;
}

.modal {
    background-color: rgba(83, 83, 87, 0.568);
    backdrop-filter: blur(25px);
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}


.modal h2 {
    margin-top: 0;
    font-size: 24px;
    color: white;
    text-align: center;
}

.modal h3 {
    font-size: 20px;
    color: #d6d6d6;
    margin-top: 20px;
    text-decoration: underline;
}

.modal p {
    font-size: 16px;
    color: #ffffff;
    line-height: 1.6;
    margin-top: 10px;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    background-color: #00000088;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1000;
}

.modal-close-button:hover {
    background-color: #000000;
}

.modal-close-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5);
}

.modal-bottom-close {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.modal-bottom-close-button {
    padding: 10px 20px;
    background-color: #022852;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.modal-bottom-close-button:hover {
    background-color: #003e81;
    color: #e4a906
}

.modal-bottom-close-button:focus {
    outline: none;
    color: #e4a906;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.modal::-webkit-scrollbar {
    width: 8px;
}

.modal::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
}

.modal::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 775px) {
    .modal {
        max-height: 50% !important;
        padding: 30px;
    }
}
