.blogSidebar-container {
  border: 1px solid transparent;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.15); /* Soft shadow for depth */
  transition: 0.6s ease;
  will-change: transform, box-shadow, color;
  overflow: scroll; /* Ensure content stays within the container during transition */
}

.sidebar-container-content {
  padding: 10px;
}

.blogSidebar-container:hover {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  transition: 0.6s ease;
  transform: scale(1.011);
  will-change: transform, box-shadow, color;
}

.blogSidebar-container h3 {
  margin-bottom: 10px;
  color: #333;
}

.blogSidebar-container ul {
  list-style: none;
  padding: 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out; /* Add transition for height */
}

.blogSidebar-container ul.expanded {
  max-height: 260px;
  overflow-y: auto;
}

#dark .blogSidebar-container li {
  border-color: #eeeeee6e;
}

.blogSidebar-container li {
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.blogSidebar-container a {
  color: #022852;
  transition: color 0.25s ease;
  text-decoration: none;
}

.blogSidebar-container.recentBlogs a{
  text-decoration: underline;
}

.view-all-categories {
  text-align: center;
  margin-top: 10px;
}

.expand-btn {
  background: none;
  border: none;
  color: #022852;
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.25s ease;
}

.expand-btn:hover {
  color: #e4a906;
}

.blogSidebar-container a:hover {
  color: #e4a906;
  transition: color 0.25s ease;
}

.blogSidebar-container li:last-child {
  border-bottom: none;
}
