.dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    margin-top: 20px;
    margin-bottom: 5rem;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

#dark .dashboard-item:hover {
    background-color: #02275252;
}


.dashboard-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 40%;
    height: 300px;
    background-color: inherit;
    color: black;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 0.2rem;
    transition: background-color 0.25s ease, color 0.25s ease, transform 0.25s ease, border 0.25s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    overflow: hidden;
    position: relative;
    border: 1px solid rgb(224, 224, 224);
}

.dashboard-item-graphic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.dashboard-label {
    position: absolute;
    width: fit-content;
    height: fit-content;
    bottom: 0;
    left: 3%;
    display: flex;
    align-items: center;
}

.dashboard-label p {
    z-index: 1;
    font-size: 2rem;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.616);
    transition: 0.25s ease-out;
    padding: 0;
    margin: 0;
}

.badge {
    background-color: #9e0808;
    color: #ffffff;
    border-radius: 4rem;
    padding: 0rem 0.4rem;
    margin-left: 0.5rem;
    font-size: 1.1rem;
    margin-top: 0.2rem;
}

.badge.green {
    background-color: green;
}

.users-badge-one {
    background-color: green;
    color: #ffffff;
    border-radius: 4rem;
    padding: 0.1rem 0.3rem;
    margin-left: 0.5rem;
    font-size: 0.9rem;
    margin-top: 0.2rem;
}

.users-badge-multi {
    background-color: rgb(182, 74, 1);
    color: #ffffff;
    border-radius: 4rem;
    padding: 0.1rem 0.3rem;
    margin-left: 0.5rem;
    font-size: 0.9rem;
    margin-top: 0.2rem;
}

.sitemap-badge-green {
    color: #ffffff;
    border-radius: 4rem;
    padding: 0.1rem 0.3rem;
    margin-left: 0.5rem;
    font-size: 0.9rem;
    margin-top: 0.3rem;
    background-color: green;
}

.dashboard-username {
    text-decoration: underline;
}


.sitemap-badge-red {
    color: #ffffff;
    border-radius: 4rem;
    padding: 0.1rem 0.3rem;
    margin-left: 0.5rem;
    font-size: 0.9rem;
    margin-top: 0.3rem;
    background-color: #9e0808;
}



#dark .dashboard-item .dashboard-label p {
    color: white;
}

#dark .dashboard-item:hover .dashboard-label p {
    color: #e4a906;
}

.dashboard-item:hover .dashboard-label p {
    color: #023268;
}

#dark .dashboard-big-icon i {
    color: rgba(161, 161, 161, 0.075);
}

.dashboard-big-icon i{
    color: rgba(0, 0, 0, 0.041);
    font-size: 36rem;
    transform: scaleY(0.9) rotate(7deg);
}

#dark .dashboard-small-icon {
    color: #7e7e7e56;
    text-shadow: 0px 0px 20px #9c9c9c4f;
}

.dashboard-small-icon {
    position: absolute;
    color: #9c9c9c4f;
    text-shadow: 0px 0px 20px #b3b3b3c0;
    font-size: 11rem;
}
.dashboard-small-icon i {
    transition: 0.25s ease-in;
}
.dashboard-item:hover .dashboard-small-icon i{
    transition: 0.25s ease-in;
}


.dashboard-item:hover {
    background-color: #f5f5f5;
    transition: 0.25s ease-out;
    border: 1px solid rgba(0, 0, 0, 0.514)
}



.sitemap-generation {
    margin-top: 2rem;
    text-align: center;
}

.sitemap-generation h2 {
    margin-bottom: 1rem;
}

.sitemap-generation button {
    padding: 0.5rem 1rem;
    background-color: #006bdd;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease
}

.sitemap-generation button:hover {
    background-color: #004faa;
}

.sitemap-generation button:focus {
    outline-offset: 3px;
}

.sitemap-generation p {
    margin-top: 1rem;
    font-size: 1rem;
    color: green;
}


@media (max-width: 850px) {
    .dashboard {
        flex-direction: column;
    }

    .dashboard-item {
        max-width: unset !important;
        width: 100% !important;
    }

}