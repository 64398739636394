.contactbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2.5rem;
    color: #fff;
    background-color: rgb(1, 0, 29);
}
.theme-wrapper #light-switch:focus + #light-switch-label .switch {
    outline: 15px solid white; /* Blue outline when focused */
    outline-offset: 20px; /* Offset the outline to create a gap */
  }
  
  /* General styles */
  .theme-wrapper {
      position: relative; /* Position the switch absolutely */
      transform: scale(0.16); /* Properly scale down the switch */
      transform-origin: top left; /* Make sure scaling happens from the top-left corner */
      z-index: 10; /* Ensure the lightswitch stays on top of other elements */
      margin-left: -0.9rem;
      margin-right: 1.5rem;
      transition: translate 0.2s ease-in-out;
      will-change: transform;
  }

  .theme-wrapper:hover {
    translate: 0 -0.07rem;
  }

.contactbar-placeholder {
    height: 2.5rem; /* Same height as your contact bar */
    width: 100%;
}

.contactbar-fixed {
    animation: slideDown 0.5s forwards;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2.5rem;
    color: #fff;
    background-color: rgb(1, 0, 29);
    position: fixed; /* Keep contact bar fixed at the top */
    top: 0;
    left: 0;
    right: 0; 
    z-index: 1000;
    margin-bottom: 10rem;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.558);
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

.contactBarContent {
    display: inline-flex;
    align-items: center;
    margin-left: 15%;
    margin-right: 15%;
    width: 70%;
    height: 100%;
}

.contactIcon {
    font-size: 1.2rem;
    transition: 0.1s ease;
}

.contactIcon:hover {
    font-size: 1.2rem;
    transform: scale(1.12);
    transition: 0.1s ease;
}

.phone {
    margin-top: 0.2rem;
    transition: transform 0.1s ease-in-out;
}

.phone:hover {
    transform: scale(1.02);
    transition: transform 0.1s ease-in-out;
}

.phone-number {
    gap:0.3rem;
    display: flex;
}


.contactItems .facebookLink a i {
    border-radius: 15%;
    transition: color 0.2s ease-in-out, border-radius 0.2s ease-in-out, background-color 0.2s ease-in-out;
    outline: 2px solid white;
    will-change: border-radius, background-color, outline;
    overflow: hidden;
    font-size: 1.35rem;
    height: 1.12rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}



.contactItems li a:hover {
    color:#e4a906;
    text-decoration: underline;
    transition: 0.2s ease;
    
}

.contactItems .facebookLink a:hover,
.contactItems .facebookLink a:focus {
    transition: color 0.2s ease-in-out, border-radius 0.2s ease-in-out;
    i{
        color: #4267B2 !important;
        background-color: white;
        border-radius: 50%;
        transform: none;
        transition: color 0.2s ease-in-out, border-radius 0.2s ease-in-out;
        will-change: border-radius, background-color, outline;
    }
    
}

.contact {
    display: flex;
    align-items: center;
    flex: 1;
}

.contactItems {
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
    display: flex;
    gap: 0.8rem;
}

.contactItems li {
    display: flexbox;
    align-items: center;
    display: flex;
    gap:0.2rem;
    margin-top: 0.3rem;
    word-break: break-all;
}


.contactItems li a{
    display: flexbox;
    transition: 0.2s ease;
}

.contactItems li i{
    color:#e4a906;
}


.contactItems a {
    color: #fff;
    text-decoration: none;
}

.consultation {
    display: flex;
}

.consultation a{
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    height: 100%;
    transition: transform 0.1s ease-in-out;
    font-size: 0.95rem;
}

.consultation a:hover {
    transform: scale(1.03);
    transition: 0.1s ease-in-out;
    color:#e4a906;
    text-decoration: underline;
}


.spacer {
    margin-right: 0.3rem;
    margin-left: 0.3rem;
    margin-bottom: 0.2rem;
    font-size: 1.2rem;
}

@media screen and (max-width: 911px) {

    .contactBarContent {
        gap: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        margin: 0 !important;
        width: 100%;
    }
    .contactItems {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .facebookLink {
        justify-content: center;
    }
    .consultation,
    .theme-wrapper {
        display: none;
    }
}