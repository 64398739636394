.slide-preview-wrapper {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    box-sizing: border-box; 
}

.slide-preview {
    position: relative;
    min-width: 160px;
    height: 110px;
    cursor: pointer;
    border: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
    transition: 0.2s ease
}

.slide-preview.selected {
    border: 2px solid #007bff;
    padding: 0.3rem; /* Add padding inside the selected border to create space */
    background-clip: padding-box; /* Ensure the border color is not covered by the padding */
    transition: 0.2s ease
}

.slide-preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

/* Style for the background color */
.slide-preview-background {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box; /* Include padding in the element's total width and height */
}

.slide-foreground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Adjust to match the padding in .slide-background */
    height: 100%; /* Adjust to match the padding in .slide-background */
    overflow: hidden;
}

.slide-color-background {
    width: 100%;
    height: 100%;
    border-radius: inherit; /* Inherit the parent radius */
}

.slide-previews {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px; /* Adds space between previews */
}
