/* Benefits.css */

.benefits div {
    margin: 0 auto;
    padding: 10px 0;
    text-align: center;
    margin-left: 15%;
    margin-right: 15%;
}

.benefits-content {
    width: 100%;
}



.benefit-item {
    opacity: 0;
    transform: translateY(8rem);
    transition: all 1s ease-in-out;
    color: rgb(1, 0, 29);
}

.benefit-item.slide-up {
    opacity: 1;
    transform: translateY(0);
}

#dark .benefits h2 {
    color: white
}

#light .benefits h2 {
    color: black
}

.benefits h2 {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 2.5rem;
}

.benefits .bold {
    font-weight: bold;
}

.benefits .font-italic {
    font-style: italic;
}

.benefits .emphasis {
    color:#e4a906;
    width: 100%;
    word-wrap: break-word;
}

#dark .benefits .accent {
    color: #e4a906
}

.benefits .accent {
    color: #022852; /* Example accent color - adjust as needed */
    margin-top: 0;
    font-size: 1.4rem;
}

.benefits-content .row {
    justify-content: center;
    gap: 5rem;
}

#dark .benefits {
    background-color: rgb(73, 76, 83);
}

#dark .benefits .step {
    background-color: rgb(180, 180, 180);
}

.benefits .step {
    flex: 1;
    flex-basis: 23%;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 10rem;
    max-width: 50%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25),
    -2px 4px 6px rgba(0, 0, 0, 0.4);
    padding: 20px;
    margin: 20px 0;
    text-align: center;
    background-color: rgb(236, 236, 236);
    transition: 1s ease;
    will-change: transform, box-shadow;
    border-radius: 0rem;
}

.benefits .step:hover {
    
    box-shadow: 4px 7px 5px rgba(0, 0, 0, 0.35),
    -4px 7px 6px rgba(0, 0, 0, 0.45);
    transform: scale(1.034);
    transition: 1s ease-out;
    will-change: transform, box-shadow;
}


.benefits .step img {
    margin-bottom: 1em;
    transform: scale(0.7);
    will-change: transform, box-shadow;
}

.benefits .step h4 {
    margin-bottom: 0.5em;
    font-size: 1.7rem;
    will-change: transform, box-shadow;
}

.benefits .step p {
    font-size: 1.1rem;
    will-change: transform, box-shadow;
}

.benefits .buttonWrapper {
    padding: 0;
    margin-top: 3rem;
}

.benefits .btn-custom {
    background-color: #022852;
    color: #e4a906;
    text-decoration: none;
    padding: 1.1rem 2rem;
    border-radius: 2rem;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 750;
    white-space: nowrap;

    &:focus {
        outline-offset: 8px;
    }
}

.benefits .btn-custom:hover {
    color: #022852; /* Replace with the actual color */
    background-color: #e4a906;

    font-weight: bolder;
    transition: 0.3s ease
  }

/* Responsive adjustments */
@media (max-width: 991px) {
    .benefits .step {
        max-width: 70%;
        flex-basis: 100%;
        word-wrap: break-word;

    }

    .benefits-content {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 0;
        padding-right: 0;
    }

    .benefits-content div {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 0;
        padding-right: 0;
    }

    .benefits .btn-custom {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 480px) {
    .benefits h2 {
        font-weight: bold;
        margin-bottom: 0.5rem;
        font-size: 2rem;
    }

    .benefits .benefits-content p {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 240px) {
    .benefits h2 {
        font-weight: bold;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
    }
}