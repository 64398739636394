.slide-actions {
    margin-bottom: 1.5rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
}


.edit-btn {
    background-color: white;
    color: black; /* Change this color to fit your design */
    padding: 10px 15px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px; /* Adds some space below the button */
    transition: background-color 0.3s ease;
}

.edit-btn:hover {
    background-color: #bebebe; /* Change hover color as needed */
    color: black;
}


.delete-btn{
    background-color: rgb(151, 5, 5);
    padding: 10px 15px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px; /* Adds some space below the button */
    transition: background-color 0.3s ease;
    color: white;
}

.delete-btn:hover {
    background-color: rgb(99, 0, 0); /* Change hover color as needed */
}

.admin-slide {
    position: relative;
    height: 80vh; /* You may also try min-height to ensure a minimum height */
    overflow: hidden;
    margin: 3rem;
    border-radius: 2rem;
}

.admin-slide .slide-content {
    position: absolute;
    top: 18%;
}
.admin-slide .slide-image {
    width: 100%;
    height: 100%; /* This ensures the image covers the entire container */
    object-fit: cover; /* This ensures the image maintains aspect ratio without being stretched */
}

.admin-slide .slide-button-link {
    color: rgba(255, 255, 255, 0.685);
    font-size: 1.4rem;
}

.admin-slide .admin-slide-button {
    display: flex;
    align-items: center;
}