.add-user-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

.add-user-form {
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 16rem;
    color: black;
  }
  
  .add-user-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
  }
  
  .add-user-form form > div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .add-user-form label {
    text-align: left;
    margin-bottom: 5px;
  }
  
  .add-user-form input[type="text"],
  .add-user-form input[type="file"],
  .add-user-form input[type="password"],
    select
   {
    padding: 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
    
  .add-user-form button[type="submit"] {
    width: 100%; /* Adjust width of the submit button as needed */
    padding: 12px 24px; /* Larger button for bigger text */
    font-size: 1.3rem; /* Bigger text on the button */
    border-radius: 5px;
    background-color: #022852;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .add-user-form button[type="submit"]:hover {
    background-color: #e4a906;
  }